const enlaces = [
    { desc: 'Obtención de FIEL Como obtener tu e.firma', link: 'https://www.sat.gob.mx/tramites/16703/obten-tu-certificado-de-e.firma-(antes-firma-electronica)' },
    { desc: 'Certificados de Sello Digital (CertiSat Web y Solcedi)', link: 'https://www.gob.mx/sat/acciones-y-programas/certisat-web/' },
    { desc: 'SICOFI', link: 'https://paic.plataforma.sat.gob.mx/nidp/idff/sso?id=FormGenCont&sid=1&option=credential&sid=1&target=https%3A%2F%2Fportalsat.plataforma.sat.gob.mx%2FSICOFI_WEB%2FModuloFoliosV2%2FPrincipal.asp' },
    { desc: 'Información general de facturación electrónica', link: 'https://www.sat.gob.mx/personas/factura-electronica' },
    { desc: 'Verificador de comprobantes impresos (captura manual y CBB)', link: 'https://www.consulta.sat.gob.mx/SICOFI_WEB/ModuloSituacionFiscal/VerificacionComprobantes.asp' },
    { desc: 'Servicio para consultar, cancelar y recuperar facturas electrónicas (CFDI)', link: 'https://www.sat.gob.mx/aplicacion/82471/consulta,-cancela-y-recupera-tus-facturas-electronicas' },
    { desc: 'IMSS', link: 'http://www.imss.gob.mx/' },
    { desc: 'IMSS – IDSE', link: 'http://idse.imss.gob.mx/imss/' },
    { desc: 'INFONAVIT', link: 'https://portalmx.infonavit.org.mx/' },
    { desc: 'Secretaría de Finanzas (CDMX)', link: 'https://www.finanzas.cdmx.gob.mx/' },
    { desc: 'Índice Nacional de Precios al Consumidor', link: 'https://www.inegi.org.mx/temas/inpc/' },
    { desc: 'Salarios mínimos vigentes', link: 'https://www.gob.mx/conasami/documentos/tabla-de-salarios-minimos-generales-y-profesionales-por-areas-geograficas' },
    { desc: 'Tipo de cambio Banco de México', link: 'https://www.banxico.org.mx/tipcamb/main.do?page=tip&idioma=sp' }
]
const EnlacesUtiles = () => (
    <div className="uk-container-expand" uk-height-viewport="expand: true">
        <div className="uk-container uk-padding-large uk-text-justify">
            <div className="uk-width-1-1@s">
                <table className="uk-table uk-table-justify uk-table-divider uk-table-hover uk-table-responsive">
                    <thead>
                        <tr>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Enlaces enlaces={enlaces} />
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

function Enlaces(props) {
    const items = props.enlaces.map((enlace, i) =>
        <tr key={i}>
            <td><a href={enlace.link} target="_blank" rel="noreferrer" className="uk-link-muted">{enlace.desc}</a></td>
        </tr>
    )
    return items
}

export default EnlacesUtiles;