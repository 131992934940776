const Politica = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <p>Bienvenidos al sitio web de Formas Digitales, valoramos su privacidad como si fuera la nuestra y hemos creado esta política de privacidad para que pueda entender cómo se utiliza la información que recopilamos de nuestros visitantes. Esta política de privacidad sólo aplica en nuestra página web <b>www.formasdigitales.mx</b></p>
            {/* Política de Privacidad */} <h3>Objetivo de la política de protección</h3>
            <ul>
                <li>Proteger los datos personales contra daño, pérdida, destrucción, alteración, divulgación y tratamiento no autorizado.</li>
                <li>Contar con medidas de seguridad, administrativas, físicas y técnicas que apoye a la protección de datos.</li>
            </ul>
            <h3>Alcance</h3>
            <p>Se limita al manejo de datos del portal y del contribuyente que accesa mediante la página oficial <a href="://www.formasdigitales.com.mx" target={'_self'}>https://www.formasdigitales.com.mx</a> o el portal <a href="https://www.portal.facturacfdi.mx" target={'_blank'}>https://www.portal.facturacfdi.mx</a></p>
            <h3>Política de Protección de Datos</h3>
            <p>En FORMAS DIGITALES se cumple con las medidas de seguridad relacionada a la protección de datos definidas en el reglamento LFPFPPP (citar glosario), así como los principios de disponibilidad, integridad y confidencialidad (citar glosario) concernientes a los clientes, colaboradores, proveedores, socios y entidades gubernamentales necesarias para desempeñar la relación jurídica vigente y de prospección comercial, la cual se encuentra en nuestra infraestructura.</p>
            <p>En ese sentido, para continuar con la seguridad de los datos personales, FD la etiqueta con el más alto grado de confidencialidad, basado en la documentación interna relacionada con la seguridad de la información y mejores prácticas para su tratamiento, tales como, Políticas de seguridad de la información, etiquetado y manejo de la información, el cual verifica constantemente que el personal cuenta con; acuerdos de confidencialidad y/no divulgación el cual compromete al personal interno a guardar la seguridad de la información, manejo de contraseñas, políticas de control de acceso físicos y lógicos, gestión de privilegios, contraseñas, cifrado y encriptación de la bases de datos, protección de datos para cuentas de usuario, contraseñas y accesos limitados al personal autorizado</p>
            <table>
                <thead>
                    <tr>
                        <td><b>Glosario</b></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="100">LFPFPPP</td>
                        <td>(Ley federal de protección de datos personales en posesión de los particulares)</td>
                    </tr>
                    <tr>
                        <td width="100">CIA</td>
                        <td>(Confidencialidad, Integridad y Disponibilidad)</td>
                    </tr>
                    <tr>
                        <td width="100">FD</td>
                        <td>FORMAS DIGITALES</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);


export default Politica;