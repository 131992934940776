import React, { Component } from 'react'

class OffCanvasMenu extends Component {

    render() {
        return (
            <div id="offcanvas-menu" uk-offcanvas="overlay: true">
                <div className="uk-offcanvas-bar">
                    <ul className="uk-nav uk-nav-default uk-light">
                        <li><a href="/"><span uk-icon="home" className="uk-margin-small-right"></span> Inicio</a></li>
                        <li><a href="/precios"><span uk-icon="cart" className="uk-margin-small-right"></span> Precios</a></li>
                        <li><a href="/nosotros"><span uk-icon="user" className="uk-margin-small-right"></span> Nosotros</a></li>
                        <li><a href="/contacto"><span uk-icon="comments" className="uk-margin-small-right"></span> Contacto</a></li>
                        <li className="uk-nav-header">Soluciones</li>
                        <li className="uk-parent">
                            <ul className="uk-nav-sub">
                                <li><a href="/soluciones/portal-de-facturacion-y-nomina-en-la-nube">Portal de facturación y nómina</a></li>
                                <li><a href="/soluciones/timbrado-web-service">Timbrado Web Service</a></li>
                                <li><a href="/soluciones/conector-digital">Conector Digital</a></li>
                                <li><a href="/soluciones/distribuidores">Distribuidores</a></li>
                                <li><a href="/soluciones/portal-autofactura">Portal Autofactura</a></li>
                                <li><a href="/soluciones/validador-xml">Validador XML</a></li>
                                <li><a href="/soluciones/addendador">Addendador</a></li>
                                <li><a href="/soluciones/central-forsedi">Central Forsedi</a></li>
                                <li><a href="/soluciones/descarga-cfdi">Descarga CFDI</a></li>
                            </ul>
                        </li>
                        <li className="uk-nav-header">Partners</li>
                        <li className="uk-parent">
                            <ul className="uk-nav-sub">
                                <li><a href="/partners/aspel">Aspel</a></li>
                            </ul>
                        </li>
                        <li className="uk-nav-header">Soporte</li>
                        <li className="uk-parent">
                            <ul className="uk-nav-sub">
                                <li><a href="/enlaces-utiles">Enlaces Útiles</a></li>
                                <li><a href="/preguntas-frecuentes">Preguntas Frecuentes</a></li>
                                <li><a href="https://forsedi.facturacfdi.mx/developers/" target="_blank" rel="noreferrer">Sitio Desarrolladores</a></li>
                            </ul>
                        </li>
                        <li className="uk-nav-divider"></li>
                        <li><a href="/aviso-de-privacidad"><span uk-icon="info" className="uk-margin-small-right"></span> Aviso de privacidad</a></li>
                        <li><a href="/politicas-de-privacidad"><span uk-icon="info" className="uk-margin-small-right"></span> Políticas de privacidad</a></li>
                        <li><a href="/bolsa-de-trabajo"><span uk-icon="users" className="uk-margin-small-right"></span> Bolsa de Trabajo</a></li>
                        <li className="uk-nav-header">Social</li>
                        <li className="uk-text-center">
                            <ul className="uk-iconnav uk-margin-top uk-margin-xlarge-bottom">
                                <li className="uk-padding-remove uk-margin-right"><a href="https://www.facebook.com/FormasDigitalesMx" target="_blank" rel="noreferrer" uk-icon="icon: facebook"></a></li>
                                <li className="uk-padding-remove uk-margin-right"><a href="https://twitter.com/FACTURACFD" target="_blank" rel="noreferrer" uk-icon="icon: twitter"></a></li>
                                <li className="uk-padding-remove uk-margin-right"><a href="https://www.youtube.com/user/facturacfdi" target="_blank" rel="noreferrer" uk-icon="icon: youtube"></a></li>
                                <li className="uk-padding-remove uk-margin-right"><a href="https://www.linkedin.com/in/formas-digitales-b8b94759" target="_blank" rel="noreferrer" uk-icon="icon: linkedin"></a></li>
                                <li className="uk-padding-remove uk-margin-right"><a href="https://www.instagram.com/formasdigitales/" target="_blank" rel="noreferrer" uk-icon="icon: instagram"></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}


export default OffCanvasMenu;