import flujo from '../../images/flujo.png'
const Conector = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <p>Si tú ya cuentas con tu propio ERP o sistema administrativo; conectamos la facturación electrónica (CFDI) con las ultimas disposiciones fiscales del SAT; sin importar que sistema estás utilizando.</p>
            <p>Puede leer los siguientes tipos de archivos</p>
            <ul className="uk-list uk-padding">
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Archivo de Texto (TXT)</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Archivo YAML</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Archivo XML</li>
            </ul>
            <p>Contamos con tres tipos de conectores, los cuales son multiempresas.</p>
            <div className="uk-child-width-1-2@s" uk-grid={"true".toString()}>
                <div><img src={flujo} alt="flujo" /></div>
            </div>
            <h2 className="uk-heading-bullet">Beneficios</h2>
            <ul className="uk-list uk-padding">
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Complementos fiscales. Añade a tus facturas electrónicas el complemento fiscal requerido de acuerdo con el giro empresarial de tu negocio.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Aseguras que todas las facturas que emitidas cumplan con las reglas de validación de acuerdo con las disposiciones establecidas por el SAT.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Portal addendador</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Resguardo de tus XML por 5 años</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Descarga masiva*</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Administrador de timbres</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Almacén digital</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Mesa de Servicio con especialistas de soporte técnico vía telefónica, email, chat o remoto disponible de Lunes a Viernes de 8:30 a.m. a 07:00 p.m.</li>
            </ul>
        </div>
    </div>
);


export default Conector;