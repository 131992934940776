import aspel from '../../images/partners/logo-aspel.svg'
import crol from '../../images/partners/logo-crol.svg'

const partners = [
    { name: 'Aspel', logo: aspel, link: '/partners/aspel'},
    { name: 'Crol', logo: crol, link: '/partners/crol' },
]
const Partners = () => (
    <div className="uk-container-expand" uk-height-viewport="expand: true">
        <div className="uk-container uk-padding-large uk-text-justify">
            <h2 className="uk-heading-bullet">Nuestros partners</h2>
            <div className="uk-child-width-1-3@s uk-grid" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card; row: false">
                <OurPartners partners={partners} />
            </div>
        </div>
    </div>
);

function OurPartners(props) {
    const items = props.partners.map((partner) =>
        <div>
            <a href={partner.link}><div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small uk-text-center"><img src={partner.logo} alt={"logo_" + partner.name} className="partner_logo"/></div></a>
        </div>
    )
    return items
}

export default Partners;