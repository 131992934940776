import icon_1 from '../../images/icons/validador/formas-digitales-icono-validador-control.png'
import icon_2 from '../../images/icons/validador/formas-digitales-icono-validador-archivo.png'
import icon_3 from '../../images/icons/validador/formas-digitales-icono-validador-3-3.png'
import icon_4 from '../../images/icons/validador/formas-digitales-icono-validador-envio.png'
import icon_5 from '../../images/icons/validador/formas-digitales-icono-validador-receptor.png'
import icon_6 from '../../images/icons/validador/formas-digitales-icono-validador-filtros.png'
import icon_7 from '../../images/icons/validador/formas-digitales-icono-validador-sucursales.png'
import icon_8 from '../../images/icons/validador/formas-digitales-icono-validador-web-service.png'
import icon_9 from '../../images/icons/validador/formas-digitales-icono-validador-estructua.png'
import icon_10 from '../../images/icons/validador/formas-digitales-icono-validador-expiracion.png'
import icon_11 from '../../images/icons/validador/formas-digitales-icono-validador-sello.png'
import icon_12 from '../../images/icons/validador/formas-digitales-icono-validador-estatus.png'

const list = [
    { icon: icon_1, desc: 'Personaliza tu portal de validación con tu logo y fondo preferido.' },
    { icon: icon_2, desc: 'Recepción de archivos por medio de tu portal o web service.' },
    { icon: icon_3, desc: 'Valida los XML 3.3 CFDi.' },
    { icon: icon_4, desc: 'Envío de archivos validados por correo electrónico, drop box o FTP.' },
    { icon: icon_5, desc: 'Valida el R.F.C. del receptor.' },
    { icon: icon_6, desc: 'Podrás consultar con diferentes filtros (R.F.C. emisor , marco de tiempo o UUID).' },
    { icon: icon_7, desc: 'Validación y filtrado por sucursales.' },
    { icon: icon_8, desc: 'Contamos con recepción de validación vía web service.' },
    { icon: icon_9, desc: 'Valida la estructura (forma – sintaxis).' },
    { icon: icon_10, desc: 'Tu paquete de validaciones no tiene fecha de expiración.' },
    { icon: icon_11, desc: 'Valida el sello del emisor y el sello del PAC que certifico el CFDI.' },
    { icon: icon_12, desc: 'Podrás verificar el status de tu comprobante en tiempo real (activo o cancelado).' }
]

const Validador = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <h2 className="uk-heading-bullet">¿Deseas verificar los comprobantes fiscales electrónicos que recibes?</h2>
            <p>Recuerda que el único esquema válido de comprobación fiscal es la factura electrónica (CFDI)</p>
            <h2 className="uk-heading-bullet">Beneficios</h2>
            <div className="uk-child-width-1-4@s uk-text-center uk-light" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card; row: false">
                <Cards list={list}/>
            </div>
        </div>
    </div>
);

function Cards(props) {
    const items = props.list.map((item, i) =>
        <div key={i}>
            <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                <img src={item.icon} alt="" />
                <p>{item.desc}</p>
            </div>
        </div>
    )
    return items
}


export default Validador;