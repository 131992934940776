const Contacto = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large">
            <div className="uk-child-width-1-3" uk-grid={"true".toString()}>
                <div className="uk-width-2-3@s">
                    <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-remove uk-visible@s">
                        <iframe title="web" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.677932014717!2d-103.45781168507311!3d20.682677986186842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428a935c7c38f6f%3A0x53d471a9846c5219!2sFormas+Continuas+de+Guadalajara+S.A.+de+C.V.!5e0!3m2!1ses-419!2smx!4v1550620096862" width="100%" height="450" frameBorder="0"></iframe>
                    </div>
                    <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-remove uk-hidden@s">
                        <iframe title="mobile" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.677932014717!2d-103.45781168507311!3d20.682677986186842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428a935c7c38f6f%3A0x53d471a9846c5219!2sFormas+Continuas+de+Guadalajara+S.A.+de+C.V.!5e0!3m2!1ses-419!2smx!4v1550620096862" width="100%" height="450" frameBorder="0"></iframe>
                    </div>
                </div>
                <div className="uk-width-1-3@s uk-text-center uk-text-left@s">
                    <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                        <h3><span className="uk-margin-right@s" uk-icon="icon: location"></span>Ubicación</h3>
                        <p>Av. Periférico Manuel Gómez Morin #7301, Col. Granja, C.P. 45010, Zapopan, Jalisco.</p>
                        <h3><span className="uk-margin-right@s" uk-icon="icon: mail"></span>Email</h3>
                        <p><a href="mailto:contacto@formasdigitales.com.mx">contacto@formasdigitales.com.mx</a></p>
                        <h3><span className="uk-margin-right@s" uk-icon="icon: receiver"></span>Teléfono</h3>
                        <p><a href="tel:3337771677">(33) 3777 1677</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);


export default Contacto;