import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
// Routing sections
// root menu
import HeaderSection from './HeaderSection'
import Home from './Home'
import Precios from './Precios'
import Nosotros from './Nosotros'
import Contacto from './Contacto'
// soluciones/..
import Addendador from './soluciones/Addendador'
import AutoFactura from './soluciones/AutoFactura'
import Central from './soluciones/Central'
import Conector from './soluciones/Conector'
import DescargaCFDI from './soluciones/DescargaCFDI'
import DescargaCFDIPlus from "./soluciones/DescargaCFDIPlus"
import Distribuidores from './soluciones/Distribuidores'
import Portal from './soluciones/Portal'
import Validador from './soluciones/Validador'
import WebService from './soluciones/WebService'
// partners/..
import Partners from './partners/Partners'
import Aspel from './partners/Aspel'
import Crol from './partners/Crol'
// soporte/..
import Faq from './soporte/Faq'
// privacidad
import Aviso from './soporte/Aviso'
import Politica from './soporte/Politica'
import BolsaTrabajo from './soporte/BolsaTrabajo'
// 404
import NotFound from './NotFound'


import precios_bg from '../images/formas-digitales-banner-precios.png';
import contacto_bg from '../images/formas-digitales-banner-contacto.png';
import nosotros_bg from '../images/formas-digitales-banner-nosotros.png';
import faq_bg from '../images/formas-digitales-banner-nosotros.png';
import aviso_bg from '../images/formas-digitales-banner-contacto.png';
import politicas_bg from '../images/formas-digitales-banner-contacto.png';
import soluciones_bg from '../images/formas-digitales-banner-soluciones.png'
import EnlacesUtiles from "./soporte/EnlacesUtiles"
const Root = () => (
    <Router>
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/precios">
                <HeaderSection title="Precios" bg={precios_bg} />
                <Precios />
            </Route>
            <Route exact path="/contacto">
                <HeaderSection title="Contacto" bg={contacto_bg} />
                <Contacto />
            </Route>
            <Route exact path="/nosotros">
                <HeaderSection title="Nosotros" bg={nosotros_bg} />
                <Nosotros />
            </Route>

            {/* Soluciones */}
            <Route exact path="/soluciones/portal-de-facturacion-y-nomina-en-la-nube">
                <HeaderSection title="Portal de Facturación y Nómina en la Nube" bg={soluciones_bg} />
                <Portal />
            </Route>
            <Route exact path="/soluciones/timbrado-web-service">
                <HeaderSection title="Timbrado Web Service" bg={soluciones_bg} />
                <WebService />
            </Route>
            <Route exact path="/soluciones/conector-digital">
                <HeaderSection title="Conector Digital" bg={soluciones_bg} />
                <Conector />
            </Route>
            <Route exact path="/soluciones/distribuidores">
                <HeaderSection title="Distribuidores" bg={soluciones_bg} />
                <Distribuidores />
            </Route>
            <Route exact path="/soluciones/portal-autofactura">
                <HeaderSection title="Portal AutoFactura" bg={soluciones_bg} />
                <AutoFactura />
            </Route>
            <Route exact path="/soluciones/validador-xml">
                <HeaderSection title="Validador XML" bg={soluciones_bg} />
                <Validador />
            </Route>
            <Route exact path="/soluciones/addendador">
                <HeaderSection title="Addendador" bg={soluciones_bg} />
                <Addendador />
            </Route>
            <Route exact path="/soluciones/central-forsedi">
                <HeaderSection title="Central Forsedi" bg={soluciones_bg} />
                <Central />
            </Route>
            <Route exact path="/soluciones/descarga-cfdi">
                <HeaderSection title="Descarga CFDI" bg={soluciones_bg} />
                <DescargaCFDI />
            </Route>
            <Route exact path="/soluciones/descarga-cfdi-plus">
                <HeaderSection title="Descarga CFDI Plus" bg={soluciones_bg}/>
                <DescargaCFDIPlus/>
            </Route>

            {/* Partners */}
            <Route exact path="/partners">
                <HeaderSection title="Partners" bg={soluciones_bg} />
                <Partners />
            </Route>
            <Route exact path="/partners/aspel">
                <HeaderSection title="Aspel" bg={soluciones_bg} />
                <Aspel />
            </Route>
            <Route exact path="/partners/crol">
                <HeaderSection title="Crol" bg={soluciones_bg} />
                <Crol />
            </Route>

            {/* Soporte */}
            <Route exact path="/enlaces-utiles">
                <HeaderSection title="Enlaces Útiles" bg={faq_bg} />
                <EnlacesUtiles />
            </Route>
            <Route exact path="/preguntas-frecuentes">
                <HeaderSection title="Preguntas Frecuentes" bg={faq_bg} />
                <Faq />
            </Route>
            <Route exact path="/aviso-de-privacidad">
                <HeaderSection title="Aviso de Privacidad" bg={aviso_bg} />
                <Aviso />
            </Route>
            <Route exact path="/politicas-de-privacidad">
                <HeaderSection title="Políticas de Privacidad" bg={politicas_bg} />
                <Politica />
            </Route>
            <Route exact path="/bolsa-de-trabajo">
                <HeaderSection title="Bolsa de Trabajo" bg={contacto_bg} />
                <BolsaTrabajo />
            </Route>
            <Route component={NotFound} />
        </Switch>
    </Router>
);


export default Root;