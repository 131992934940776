
import React, { useState } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'

const SECRET_KEY_reCAPTCHA = '6Lcoyt4UAAAAAJfMe78YBeKfwp2xDD6AvUgZUYZt'
const FORM_ENDPOINT = 'https://forsedi.facturacfdi.mx/AppConectores/partners/addClient'


const ContactForm = () => (
    <MainForm />
);



const MainForm = () => {
    const formik = useFormik({
        initialValues: {
            partner: '',
            rfc: '',
            razonsocial: '',
            contacto: '',
            email: '',
            telefono: '',
            campania: '',
            captcha: ''
        },
        onSubmit: values => {
            document.getElementById("enviaFormFooter").setAttribute("disabled","")
            let t = ''
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(SECRET_KEY_reCAPTCHA, { action: 'submit' }).then(function (token) {
                    t = token
                });
            })
            setTimeout(() => {
                values.captcha = t
                values.rfc = rfc
                axios.post(FORM_ENDPOINT, null, {
                    params: {
                        partner: values.partner,
                        rfc: values.rfc,
                        razonsocial: values.razonsocial,
                        contacto: values.contacto,
                        email: values.email,
                        telefono: values.telefono,
                        campania: values.campania,
                        captcha: values.captcha
                    }
                })
                    .then(res => {
                        formik.resetForm()
                        const s = document.createElement('script')
                        const scriptText = document.createTextNode("UIkit.modal.dialog('<p class=\"uk-padding\">"+res.data+"</p>')")
                        s.type = 'text/javascript'
                        s.appendChild(scriptText)
                        document.body.appendChild(s)
                        document.getElementById("enviaFormFooter").removeAttribute("disabled","")
                        setRfc("")
                    }).catch((error) => {
                        console.log(error);
                        const s = document.createElement('script')
                        s.type = 'text/javascript'
                        s.innerHTML = "UIkit.modal.dialog('<p class=\"uk-padding\">Ocurrió un error al registrar la informacion</p>')"
                        document.body.appendChild(s)
                        document.getElementById("enviaFormFooter").removeAttribute("disabled","")
                        setRfc("")
                    })
            }, 1000);
        },
    });
    
    const[rfc,setRfc]=useState('');

    const handleInput=(event)=>{
        event.preventDefault();
        setRfc(event.target.value);
          
    }

    const changeCase=(event)=>{
        event.preventDefault();
        setRfc(event.target.value.toUpperCase());
    }

    return (
        <div className="uk-container-expand uk-background-muted">
            <div className="uk-container uk-padding uk-marging-top">
                <h1 className="uk-heading-line uk-text-center" uk-scrollspy="cls: uk-animation-fade; repeat: true"><span>Contáctanos para ayudarte</span></h1>
                <p className="uk-text-center">Atención personalizada, solicitud de demostraciones de productos, dudas sobre funciones y/o costos. Cualquiera que sea tu solicitud, trabajaremos en ella.</p>
                <form className="uk-grid-small" uk-grid={"true".toString()} onSubmit={formik.handleSubmit}>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">&bull; ¿Cómo nos conociste?</label>
                        <select className="uk-select uk-border-rounded" id="partner" name="partner" onChange={formik.handleChange} value={formik.values.partner}>
                            <option>---</option>
                            <option>Aspel</option>
                            <option>Email</option>
                            <option>Facebook</option>
                            <option>Instagram</option>
                            <option>WhatsApp</option>
                            <option>Pagina Web</option>
                            <option>Google</option>
                            <option>LinkedIn</option>
                            <option>X (Twitter)</option>
                            <option>Otro</option>
                        </select>
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">&bull; Requiero información sobre</label>
                        <select className="uk-select uk-border-rounded" id="campania" onChange={formik.handleChange} value={formik.values.campania}>
                            <option value="">---</option>
                            <option value="Portal en la nube">Portal De Facturación y Nómina En la Nube</option>
                            <option value="Timbrado WebService">Timbrado Web Service</option>
                            <option value="Conector">Conector Digital</option>
                            <option value="Distribuidores">Distribuidores</option>
                            <option value="Autofactura">Portal Autofactura</option>
                            <option value="Validador">Validador XML</option>
                            <option value="Addendador">Addendador</option>
                            <option value="Central">Central Forsedi</option>
                            <option value="Descarga CFDI">Descarga CFDI</option>
                        </select>
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">RFC</label>
                        <input className="uk-input uk-border-rounded" type="text" maxLength="13" onChange={handleInput} onKeyUp={changeCase} pattern="^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9]([0-2][0-9]|30|31)[A-Z,0-9][A-Z,0-9][0-9,A-Z]" id="rfc" name="rfc" value={rfc} />
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">&bull; Nombre de contacto</label>
                        <input className="uk-input uk-border-rounded" type="text" maxLength="150" id="contacto" name="contacto" onChange={formik.handleChange} value={formik.values.contacto} required/>
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">&bull; Razón Social</label>
                        <input className="uk-input uk-border-rounded" type="text" maxLength="150" id="razonsocial" name="razonsocial" onChange={formik.handleChange} value={formik.values.razonsocial} required/>
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">&bull; Email</label>
                        <input className="uk-input uk-border-rounded" type="email" maxLength="150" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} required/>
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" htmlFor="form">&bull; Teléfono</label>
                        <input className="uk-input uk-border-rounded" type="text" maxLength="10" id="telefono" name="telefono" onChange={formik.handleChange} value={formik.values.telefono} required/>
                    </div>
                    <div className="uk-width-1-4@s">
                        <label className="uk-form-label" style={{ color: "transparent" }}>.</label>
                        <button className="uk-button uk-button-primary uk-width-1-1 uk-border-rounded" type="submit" id="enviaFormFooter" style={{ background: "rgb(38, 50, 56)" }}>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;