const SocialMedia = () => (
    <div className="socialmedia-bar uk-visible@s">
        <ul className="uk-iconnav uk-iconnav-vertical uk-light">
            <li className="uk-margin-small facebook" uk-tooltip="title: Facebook; pos: right; offset: 20"><a href="https://www.facebook.com/FormasDigitalesMx" target="_blank" rel="noreferrer" uk-icon="icon: facebook"></a></li>
            {/*<li className="uk-margin-small twitter" uk-tooltip="title: Twitter; pos: right; offset: 20"><a href="https://twitter.com/FACTURACFD" target="_blank" rel="noreferrer" uk-icon="icon: twitter"></a></li>*/}
            <li className="uk-margin-small youtube" uk-tooltip="title: YouTube; pos: right; offset: 20"><a href="https://www.youtube.com/user/facturacfdi" target="_blank" rel="noreferrer" uk-icon="icon: youtube"></a></li>
            <li className="uk-margin-small linkedin" uk-tooltip="title: LinkedIn; pos: right; offset: 20"><a href="https://www.linkedin.com/in/formas-digitales-b8b94759" target="_blank" rel="noreferrer" uk-icon="icon: linkedin"></a></li>
            <li className="uk-margin-small instagram" uk-tooltip="title: Instagram; pos: right; offset: 20"><a href="https://www.instagram.com/formasdigitales/" target="_blank" rel="noreferrer" uk-icon="icon: instagram"></a></li>
            <li className="uk-margin-small teamviewer" uk-tooltip="title: TeamViewer; pos: right; offset: 20"><a href="https://www.teamviewer.com/es-mx/descarga" target="_blank" rel="noreferrer" uk-icon="icon: teamviewer"></a></li>
        </ul>
    </div>
);

export default SocialMedia;