import React, { Fragment } from 'react'

const documents = [
    { id: 'd-1', desc: 'Documento con preguntas frecuentes relacionadas al complemento Carta Porte v2.0.', downloadPath: 'http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/Preguntas_frecuentes_CartaPorte.pdf', published: '23-01-2023' },
    { id: 'd-2', desc: 'Documento con preguntas frecuentes relacionadas al Recibo de Nómina v1.2 (vigente)', downloadPath: 'http://omawww.sat.gob.mx/tramitesyservicios/Paginas/Preguntas_frecuentes_Nomina_1_2.htm', published: '24-03-2017' },
    { id: 'd-2', desc: 'Documento con preguntas frecuentes relacionadas con el Complemento de Pago 2.0', downloadPath: 'http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/PregFrec_RP.pdf', published: '07-09-2017' },
    { id: 'd-3', desc: 'Documento con preguntas frecuentes sobre el CFDI de Retenciones e Información de Pagos', downloadPath: 'https://www.sat.gob.mx/cs/Satellite?blobcol=urldata&blobkey=id&blobtable=MungoBlobs&blobwhere=1461173888238&ssbinary=true', published: '2020' }
]

const facturas_electronicas = [
    { id: 'fe-1', question: '¿Qué es un CFDI?', answer: 'Es el formato digital de una factura electrónica, cuya sigla significa Comprobante Fiscal Digital por Internet. Por sus características permite el registro de la información de un intercambio comercial para su reconocimiento por la autoridad reguladora, en este caso, el SAT.' },
    { id: 'fe-2', question: '¿Qué significa timbrar una factura electrónica?', answer: 'Es el sello que asigna un Proveedor Autorizado de Certificación a las facturas electrónicas que cumplen con la información requerida por el SAT, con lo que valida que la información en su contenido es apta para usarse con fines fiscales.' },
    { id: 'fe-3', question: '¿Qué es un CSD o Sello Digital?', answer: 'Es un archivo digital proporcionado por el SAT a personas físicas o morales, en cuyo contenido resguarda la información de su propietario y la respalda en un documento fiscal, para evitar su falsificación.' },
    { id: 'fe-4', question: '¿Por qué debo emitir facturas electrónicas?', answer: 'Emitir facturas electrónicas es una obligación establecida desde el 2014 por el Servicio de Administración Tributaria tanto para personas físicas como morales. Sin embargo, emitirlas trae consigo beneficios como la deducción de gastos o certeza de la legalidad de una transacción.' },
    { id: 'fe-5', question: '¿Qué es una addenda?', answer: 'Es de uso comercial, mas no fiscal en la cual se agrega información adicional a una factura electrónica por solicitud del receptor, en cuyo contenido se pueden encontrar datos comerciales, logísticos y de operación de las empresas.' }
]
const anexo20 = [
    { id: 'an-1', question: '¿Qué es el Anexo 20?', answer: 'Es la forma de elaborar un Comprobante Fiscal Digital por Internet ya que en su contenido determina cómo generar, emitir, cancelar, firmar y añadir un complemento o addenda a una factura electrónica.' },
    { id: 'an-2', question: '¿Qué contiene el Anexo 20?', answer: 'De acuerdo con el SAT, el Anexo 20 se compone por los siguientes documentos: <ul> <li>Esquema (xsd)</li> <li>Estándar (pdf)</li> <li>Secuencia de cadena original (xslt)</li> <li>Catálogo de datos (xsd)</li> <li>Patrón de datos (xsd)</li> <li>Esquema del timbre fiscal digital versión 1.1 (xsd)</li> <li>Secuencia de cadena original del timbre fiscal digital (xslt)</li> </ul>' },
    { id: 'an-3', question: '¿Qué son las reglas de validación ?', answer: 'Las reglas de validación son los criterios que confirman que la información requerida para generar una factura electrónica sea ingresada correctamente, es decir, en el lugar y en la forma solicitada por la autoridad fiscal. Se clasifican en aritméticas, congruentes y condicionales.' },
    { id: 'an-4', question: '¿Qué cambió en el Anexo 20 para 2023?', answer: '<p>A partir del 1 de enero de 2022, se actualizó la versión del CFDI pasando a la versión 4.0 y desde el 1 de abril de 2023 es la única válida.:</p> <p> Los principales cambios son:</p> <ul> <li>El campo "Nombre denominación o razón social del receptor" es un campo obligatorio.</li> <li>Se incorporan los campos "Régimen fiscal del receptor" y “Domicilio fiscal del receptor”, los cuales son de uso obligatorio.</li> <li>Se incorpora el campo "Exportación", donde deberá señalarse si el comprobante corresponde a una operación de exportación definitiva o temporal.</li> <li>Se incorporan los campos "Periodicidad", "Meses" y "Año", para efectos de la emisión de las facturas globales.</li> <li>Se adiciona el elemento "ACuentaTerceros", el cual sustituye al "Complemento Concepto PorCuentadeTerceros".</li> <li>Se adiciona el campo "Objeto del impuesto"</li> </ul>' }
]
const cfdi40 = [
    { id: 'cfdi-1', question: '¿Cuáles son los cambios de la factura electrónica a partir del 1 enero de 2022?', answer: '<ul> <li>Existe la versión 4.0.</li> <li>Incluye de manera obligatoria el nombre y código postal del domicilio fiscal del emisor y del receptor.</li> <li>Incluye campos para identificar las operaciones donde exista una exportación de mercancías.</li> <li>Identifica si las operaciones que ampara el comprobante son objeto de impuestos indirectos.</li> <li>Incorpora nuevos apartados para reportar información respecto de las operaciones con el público en general; así como, aquellas que se realicen a cuenta de terceras personas.</li> </ul>' },
    { id: 'cfdi-2', question: '¿Cuál es el CFDI que ampara retenciones e información de pagos?', answer: '<ul> <li>Se actualiza a la versión 2.0.</li> <li>Incluye de manera obligatoria el nombre y domicilio fiscal del emisor y del receptor.</li> <li>Permite relacionar otros CFDI.</li> </ul>' },
    { id: 'cfdi-3', question: '¿Qué sucederá con el complemento para recepción de pagos?', answer: '<ul> <li>Se actualiza a la versión 2.0.</li> <li>Se incluyen nuevos campos para identificar sí los pagos de las operaciones que ampara el comprobante son objeto de impuestos.</li> <li>Se incluye un nuevo apartado con el resumen de los importes totales de los pagos realizados expresados en moneda nacional, así como los impuestos que se trasladan.</li> </ul>' },
    { id: 'cfdi-4', question: '¿Qué cambios tendrá el servicio de cancelación de facturas?', answer: '<ul> <li>A partir del 2022 será necesario señalar el motivo de la cancelación de los comprobantes.</li> <li>Al seleccionar como motivo de cancelación la clave 01 “Comprobante emitido con errores con relación” deberá relacionarse el folio fiscal del comprobante que sustituye al cancelado.</li> <li>Se actualizan los plazos para realizar la cancelación de facturas.</li> </ul>' },
    { id: 'cfdi-5', question: 'Si emití una factura o CFDI con la versión 3.3, al solicitar la cancelación ¿es necesario indicar el motivo de cancelación o solo aplica para la versión 4.0?', answer: '<li>A partir del 1 de enero de 2022 será requerido señalar el motivo por el cual se cancela el comprobante, esto aplica a todas las versiones de la factura o CFDI.</li> <li>Fundamento legal: Artículo 29-A sexto párrafo del Código Fiscal de la Federación.</li>' },
    { id: 'cfdi-6', question: 'Emití una factura, pero mi cliente me pide cancelarla por errores en la descripción, ¿cómo debo realizar la cancelación de dicho comprobante? ', answer: '<p>Al momento de realizar la solicitud de cancelación, sigue estos pasos:</p> <ul> <li>Deberás emitir el comprobante que contiene los datos correctos y registrar la clave “04” (Sustitución de los CFDI previos) relacionando el folio fiscal del comprobante que se sustituye. </li> <li>Podrás realizar la solicitud de cancelación ingresando a sat.gob.mx, en el servicio Cancela y recupera tus facturas, o bien, a través de los servicios de un PAC (Proveedor de Certificación Autorizado). </li> <li>Al registrar la solicitud de cancelación deberás seleccionar la opción "01" Comprobante emitido con errores con relación e incluir el folio fiscal del comprobante emitido en el paso 1. </li> <li>Al enviar tu solicitud de cancelación se validará si se requiere la aceptación del receptor para llevar a cabo la cancelación. </li> </ul> <p>Fundamento legal: Artículo 29-A sexto párrafo del Código Fiscal de la Federación</p>' }
]
const complementos = [
    { id: 'comp-1', question: '¿Cuántos complementos existen?', answer: 'De acuerdo a la Resolución Miscelánea Fiscal para 2017, se establecen 29 complementos para el uso de los contribuyentes. A continuación te enlistamos cuáles son: <ul> <li>Timbre fiscal digital (TFD)</li> <li>Estado de cuenta de combustibles de monederos electrónicos</li> <li>Donatarias</li> <li>Compra venta de divisas</li> <li>Otros derechos e impuestos</li> <li>Leyendas fiscales</li> <li>Persona física integrante de coordinado</li> <li>Turista pasajero extranjero</li> <li>CFDI Registro fiscal</li> <li>Pago en especie</li> <li>INE</li> <li>Vales de despensa</li> <li>Consumo de combustibles</li> <li>Aerolíneas.</li> <li>Notarios públicos</li> <li>Vehículo usado.</li> <li>Servicios parciales de construcción</li> <li>Renovación y sustitución de vehículos</li> <li>Certificado de destrucción</li> <li>Obras de arte plásticas y antigüedades</li> <li>Comercio exterior</li> <li>Recepción de pagos</li> </ul>' },
    { id: 'comp-2', question: '¿Cuándo comenzará a usarse el complemento para pagos?', answer: 'La obligación inicia a partir del 1 de julio, sin embargo, si emites facturas electrónicas en la versión 3.3, el uso de este complemento es opcional hasta el 30 de noviembre del 2017.' },
    { id: 'comp-3', question: '¿Cómo se cancela una factura versión 4.0?', answer: 'Proceso de cancelación bajo el nuevo esquema: <ul> <li>Los emisores deberán enviar la solicitud de cancelación de la factura a través del Portal del SAT o de los servicios un proveedor de certificación.</li> <li>Cuando se requiera la aceptación para la cancelación, el receptor de la factura, recibirá un mensaje de interés en su Buzón Tributario, informando que existe una solicitud de cancelación.</li> <li>El receptor deberá manifestar la aceptación o rechazo de la cancelación a través del Portal del SAT o bien vía un proveedor de certificación dentro de los tres días hábiles siguientes contados a partir de la recepción de la solicitud. De no emitir respuesta, se considera como una positiva ficta y la factura será cancelada.</li> <li>En caso de que la solicitud de cancelación no requiera aceptación por parte del receptor, la factura se cancelará de manera inmediata.</li> </ul> Las peticiones de cancelación, consulta de estado de la factura, la aceptación o rechazo de la cancelación y la consulta de documentos relacionados, se podrá realizar por el portal del SAT o mediante los servicios de un proveedor de certificación. Éste último tiene la opción de realizar estas acciones de manera masiva.' },
    { id: 'comp-4', question: '¿Debo usar el complemento para pagos por un anticipo?', answer: 'No, un anticipo no se considera un pago en parcialidades. De acuerdo con el Anexo 6 de la Guía de llenado para el CFDI 3.3 se debe emitir una factura por el valor del anticipo, otro por el valor de la operación y finalmente uno de tipo Egreso.' },
    { id: 'comp-5', question: '¿Bajo qué supuestos es obligatorio el registro de información en los apartados ImpuestosTrasladadosdelServicio y ComisionDelServicio en el complemento Servicios de Plataformas Tecnológicas?', answer: 'Para los apartados “ImpuestosTrasladadosdelServicio” y “ComisionDelServicio”, es obligatorio registrarlos cuando en el campo “FormaPagoServ” se registre una clave diferente de 09 “Otros ingresos por premios, bonificaciones o análogos”.' }
]


const Faq = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            {/* PREGUNTAS FRECUENTES SAT 2020 */}
            <h2 className="uk-heading-bullet">Preguntas Frecuentes SAT 2023</h2>
            <dl className="uk-description-list">
                <DownloadFiles documents={documents} />
            </dl>
            <QuestionsAccordion sectionTitle='Facturas Electrónicas' questions={facturas_electronicas} id='1' />
            <QuestionsAccordion sectionTitle='Anexo 20' questions={anexo20} id='2' />
            <QuestionsAccordion sectionTitle='CFDI 4.0' questions={cfdi40} id='3' />
            <QuestionsAccordion sectionTitle='Complementos y Cancelación del CFDI' questions={complementos} id='4' />
        </div>
    </div>
);

function DownloadFiles(props) {
    const content = props.documents.map((document) =>
        <>
            <dt>{document.desc}</dt>
            <dd><a href={document.downloadPath} target="_blank" download className="uk-text-small uk-link">Descargar</a> <span className="uk-text-meta">(Publicado el: {document.published})</span></dd>
        </>
    );
    return content;
}

function QuestionsAccordion(props) {
    const title = [<h2 className="uk-heading-bullet">{props.sectionTitle}</h2>]
    const items = props.questions.map((question) =>
        < li key={question.id} >
            <a className="uk-accordion-title uk-text-default" href="#">{question.question}</a>
            <div className="uk-accordion-content" dangerouslySetInnerHTML={{ __html: question.answer }}></div>
        </li >
    )
    const accordion = [<ul uk-accordion={"true".toString()} key={props.key}>{items}</ul>]
    return title.concat(accordion);
}

export default Faq;