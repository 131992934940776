const WebService = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large">            
            <p>La cantidad de timbres contratados no tienen fecha de caducidad, contaras con cancelaciones sin costo y un portal administrador.</p>
            <h2 className="uk-heading-bullet">¿Qué obtienes con la Solución Timbrado Web Service?</h2>
            <ul className="uk-list uk-padding">
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Timbrado de CFDI 4.0.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Complementos fiscales. Añade a tus facturas electrónicas el complemento fiscal requerido de acuerdo al giro empresarial de tu negocio.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Aseguras que todas las facturas que emitidas cumplan con las reglas de validación de acuerdo a las disposiciones establecidas por el SAT.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Timbrado de tus facturas en 60 milisegundos.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Cancelación de comprobantes fiscales sin costo.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Portal addendador</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Resguardo de tus XML por 5 años</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Descarga masiva*</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Administrador de timbres</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Almacén digital</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Mesa de Servicio con especialistas de soporte técnico vía telefónica, email, chat o remoto disponible de Lunes a Viernes de 8:30 a.m. a 07:00 p.m.</li>
            </ul>
        </div>
    </div>
);

export default WebService;