import laptop from '../images/formas-digitales-nosotros-computadora.png'
import mision_bg from '../images/formas-digitales-nosotros-mision.png'
import vision_bg from '../images/formas-digitales-nosotros-vision.png'
import mision_icon from '../images/formas-digitales-icono-mision.png'
import vision_icon from '../images/formas-digitales-icono-vision.png'
import innovacion_icon from '../images/formas-digitales-icono-innovacion.png'
import calidad_icon from '../images/formas-digitales-icono-calidad.png'
import honestidad_icon from '../images/formas-digitales-icono-honestidad.png'
import confidencialidad_icon from '../images/formas-digitales-icono-confidencialidad.png'

const Nosotros = () => (
    <div className="uk-container-expand">
        <We />
        <MisionVision />
        <Valores />
    </div>
);

const We = () => (
    <div className="uk-container-expand nosotros-bg">
        <div className="uk-container uk-padding-large">
            <div className="uk-grid-large uk-child-width-expand@s" uk-grid={"true".toString()}>
                <div>
                    <div className="uk-card uk-margin-top">
                        <p>Somos un PAC autorizado y certificado por el SAT No. 55502, con su razón social FORMAS CONTINUAS DE GUADALAJARA, S.A. DE C.V., su nombre comercial FORMAS DIGITALES; es una <strong>empresa mexicana con más de {(new Date().getFullYear()) - 1984} años de experiencia en el servicio de impresión de documentos fiscales y de alta seguridad</strong>.</p>
                        <p>A partir de 2010 decide ingresar como PACCFD y proveer servicio de emisión de Comprobantes Fiscales Digitales (CFD), posteriormente el SAT cambiaría este esquema con la aparición de los Comprobantes Fiscales Digitales por Internet (CFDI), de lo cual obliga a transformarnos en PAC CFDI, desarrollando a partir de ahí Formas Digitales.</p>
                        <p>Nuestro principal objetivo es agilizar e integrar los procesos administrativos de nuestros clientes; para que ellos puedan dedicar su tiempo y recursos en actividades que generen valor a sus negocios.</p>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-body uk-text-center">
                        <img src={laptop} alt="we_laptop" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);


const MisionVision = () => (
    <div className="uk-container">
        <div className="uk-child-width-1-2@m uk-grid-collapse" uk-grid={"true".toString()}>
            <div>
                <div className="uk-inline">
                    <img src={mision_bg} alt="" />
                    <div className="uk-overlay-formas uk-position-cover"></div>
                    <div className="uk-position-center uk-padding-large uk-light uk-text-white uk-text-center">
                        <img src={mision_icon} alt="mision-icon" />
                        <h2>Misión</h2>
                        <p>Proveer a nuestros clientes soluciones de tecnologías de la información con los estándares de la calidad, que les permitan mejorar su productividad.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="uk-inline">
                    <img src={vision_bg} alt="" />
                    <div className="uk-overlay-formas uk-position-cover"></div>
                    <div className="uk-position-center uk-padding-large uk-light uk-text-white uk-text-center">
                        <img src={vision_icon} alt="vision-icon" />
                        <h2>Visión</h2>
                        <p>Posicionar a la empresa como el líder del mercado en la República Mexicana.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Valores = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding uk-text-center uk-marging-top">
            <h1 className="uk-heading-line" uk-scrollspy="cls: uk-animation-fade; repeat: true"><span>Valores</span></h1>
            <div className="uk-grid-collapse uk-child-width-1-4@s uk-text-center uk-margin-large-top" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card">
                <div>
                    <div className="uk-card uk-background-default uk-padding uk-text-right">Proveer una mejora continua en la implementación de herramientas de tecnología de vanguardia en nuestros productos.</div>
                </div>
                <div>
                    <div className="uk-card uk-background-muted uk-padding uk-card-vision"><img src={innovacion_icon} alt="innovacion"/><br/><span>Innovación</span></div>
                </div>
                <div>
                    <div className="uk-card uk-background-muted uk-padding uk-card-vision"><img src={calidad_icon} alt="innovacion"/><br/><span>Calidad</span></div>
                </div>
                <div>
                    <div className="uk-card uk-background-default uk-padding uk-text-left">Generar productos y servicios para satisfacer a nuestros clientes.</div>
                </div>
                <div>
                    <div className="uk-card uk-background-default uk-padding uk-text-right">Obtener la conﬁanza de los clientes a través de productos que satisfagan cabalmente sus necesidades y mejore su productividad.</div>
                </div>
                <div>
                    <div className="uk-card uk-background-muted uk-padding uk-card-vision"><img src={honestidad_icon} alt="innovacion"/><br/><span>Honestidad</span></div>
                </div>
                <div>
                    <div className="uk-card uk-background-muted uk-padding uk-card-vision"><img src={confidencialidad_icon} alt="innovacion"/><br/><span>Confidencialidad</span></div>
                </div>
                <div>
                    <div className="uk-card uk-background-default uk-padding uk-text-left">Es nuestro deber y responsabilidad hacer un buen uso de la información y brindar seguridad ante posibles contingencias.</div>
                </div>
            </div>
        </div>
    </div >
);

export default Nosotros;