import icon_addenda_1 from '../../images/icons/addendador/abaseguros.png'
import icon_addenda_2 from '../../images/icons/addendador/ahmsa.png'
import icon_addenda_3 from '../../images/icons/addendador/amece.png'
import icon_addenda_4 from '../../images/icons/addendador/asonioscoc.png'
import icon_addenda_5 from '../../images/icons/addendador/axa.png'
import icon_addenda_6 from '../../images/icons/addendador/basware.png'
import icon_addenda_7 from '../../images/icons/addendador/calsonic_kansei.png'
import icon_addenda_8 from '../../images/icons/addendador/casaley.png'
import icon_addenda_9 from '../../images/icons/addendador/cinepolis.png'
import icon_addenda_10 from '../../images/icons/addendador/comercialmexicana.png'
import icon_addenda_11 from '../../images/icons/addendador/coppel.png'
import icon_addenda_12 from '../../images/icons/addendador/costco.png'
import icon_addenda_13 from '../../images/icons/addendador/cotemar.png'
import icon_addenda_14 from '../../images/icons/addendador/deloitte.png'
import icon_addenda_15 from '../../images/icons/addendador/dhl.png'
import icon_addenda_16 from '../../images/icons/addendador/dupont.png'
import icon_addenda_17 from '../../images/icons/addendador/elektra.png'
import icon_addenda_18 from '../../images/icons/addendador/envasesuniversales.png'
import icon_addenda_19 from '../../images/icons/addendador/femsa.png'
import icon_addenda_20 from '../../images/icons/addendador/fragua.png'
import icon_addenda_21 from '../../images/icons/addendador/ado.png'
import icon_addenda_22 from '../../images/icons/addendador/hersheys.png'
import icon_addenda_23 from '../../images/icons/addendador/honda_ap.png'
import icon_addenda_24 from '../../images/icons/addendador/iamsa.png'
import icon_addenda_25 from '../../images/icons/addendador/iusa.png'
import icon_addenda_26 from '../../images/icons/addendador/kn_recepcion.png'
import icon_addenda_27 from '../../images/icons/addendador/lala.png'
import icon_addenda_28 from '../../images/icons/addendador/lowes.png'
import icon_addenda_29 from '../../images/icons/addendador/mabe.png'
import icon_addenda_30 from '../../images/icons/addendador/oxxo.png'
import icon_addenda_31 from '../../images/icons/addendador/pepsico.png'
import icon_addenda_32 from '../../images/icons/addendador/pilgrims.png'
import icon_addenda_33 from '../../images/icons/addendador/pisa.png'
import icon_addenda_34 from '../../images/icons/addendador/qualitas.png'
import icon_addenda_35 from '../../images/icons/addendador/sanmina.png'
import icon_addenda_36 from '../../images/icons/addendador/sanofi.png'
import icon_addenda_37 from '../../images/icons/addendador/santander.png'
import icon_addenda_38 from '../../images/icons/addendador/soriana.png'
import icon_addenda_39 from '../../images/icons/addendador/supercity.png'
import icon_addenda_40 from '../../images/icons/addendador/tiendasextra.png'
import icon_addenda_41 from '../../images/icons/addendador/tvazteca.png'
import icon_addenda_42 from '../../images/icons/addendador/walmart.png'
import icon_addenda_43 from '../../images/icons/addendador/wvoit.png'
import icon_addenda_44 from '../../images/icons/addendador/zfmexico.png'
const addendas = [
    { icon: icon_addenda_1, desc: "ABA Seguros" },
    { icon: icon_addenda_2, desc: "Ahmsa" },
    { icon: icon_addenda_3, desc: "Amece" },
    { icon: icon_addenda_4, desc: "Asonioscoc" },
    { icon: icon_addenda_5, desc: "AXA Seguros" },
    { icon: icon_addenda_6, desc: "Basware" },
    { icon: icon_addenda_7, desc: "Calsonic Kansei" },
    { icon: icon_addenda_8, desc: "Casa Ley" },
    { icon: icon_addenda_9, desc: "Cinepolis" },
    { icon: icon_addenda_10, desc: "Comercial Mexicana" },
    { icon: icon_addenda_11, desc: "Coppel Muebles" },
    { icon: icon_addenda_12, desc: "Costco" },
    { icon: icon_addenda_13, desc: "Cotemar" },
    { icon: icon_addenda_14, desc: "Deloitte" },
    { icon: icon_addenda_15, desc: "DHL" },
    { icon: icon_addenda_16, desc: "Dupont" },
    { icon: icon_addenda_17, desc: "Elektra" },
    { icon: icon_addenda_18, desc: "Envases Universales" },
    { icon: icon_addenda_19, desc: "Femsa" },
    { icon: icon_addenda_20, desc: "Fragua" },
    { icon: icon_addenda_21, desc: "Grupo ADO" },
    { icon: icon_addenda_22, desc: "Hershey´s" },
    { icon: icon_addenda_23, desc: "Honda Partes" },
    { icon: icon_addenda_24, desc: "Iamsa" },
    { icon: icon_addenda_25, desc: "Iusa" },
    { icon: icon_addenda_26, desc: "KN Recepción" },
    { icon: icon_addenda_27, desc: "Lala" },
    { icon: icon_addenda_28, desc: "Lowes" },
    { icon: icon_addenda_29, desc: "Mabe" },
    { icon: icon_addenda_30, desc: "Oxxo" },
    { icon: icon_addenda_31, desc: "Pepsico" },
    { icon: icon_addenda_32, desc: "Pilgrim's Pride de México" },
    { icon: icon_addenda_33, desc: "PISA" },
    { icon: icon_addenda_34, desc: "Qualitas" },
    { icon: icon_addenda_35, desc: "Sanmina-SCI" },
    { icon: icon_addenda_36, desc: "Sanofi" },
    { icon: icon_addenda_37, desc: "Santander" },
    { icon: icon_addenda_38, desc: "Soriana" },
    { icon: icon_addenda_39, desc: "Supercity" },
    { icon: icon_addenda_40, desc: "Tiendas Extra" },
    { icon: icon_addenda_41, desc: "TV AZTECA" },
    { icon: icon_addenda_42, desc: "Walmart" },
    { icon: icon_addenda_43, desc: "WVoit" },
    { icon: icon_addenda_44, desc: "ZF MEXICO" }
]
const Addendador = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <h2 className="uk-heading-bullet">¿Que es una addenda?</h2>
            <p>
                Una addenda un nodo donde se incluyen datos comerciales al CFDI, esta contiene datos para el mejor control de los procesos operativos, sistema de inventarios, contables, embarques, etc.
            </p>
            <p>
                Es importante aclarar que a diferencia de un complemento fiscal, esta información no es requerida ni regulada por el SAT, por lo que no tiene efectos fiscales, únicamente comerciales.
            </p>
            <h2 className="uk-heading-bullet">¿Cómo funciona?</h2>
            <p>
                Es muy sencillo utilizar nuestro portal:
            </p>
            <ul className="uk-list uk-padding-small uk-list-disc">
                <li>
                    Ingrese al portal utilizando su usuario y contraseña de timbrado.
                </li>
                <li>
                    Seleccione la addenda que desea agregar, cargue el archivo XML de su CFDI y precione aceptar.
                </li>
                <li>
                    Capture los datos necesarios para generar la addenda.
                </li>
                <li>
                    Al finalizar se descargara una copia de su CFDI con su addenda.
                </li>
            </ul>
            <h2 className="uk-heading-bullet">Más de 50 addendas</h2>
            <div>
                <div className="uk-grid-collapse uk-child-width-auto uk-text-center uk-margin-large-top" uk-grid={"true".toString()}>
                    <Addendas list={addendas} />
                </div>
            </div>
        </div>
    </div>
);


function Addendas(props) {
    const items = props.list.map((item, i) =>
        <div key={i} className="uk-margin-small uk-padding-small">
            <span><img width='48px' src={item.icon} alt={item.desc} uk-tooltip={item.desc}/></span>
        </div>
    )
    return items
}


export default Addendador;