import icon_1 from '../../images/icons/autofactura/formas-digitales-icono-autofactura-ticket-1.png'
import icon_2 from '../../images/icons/autofactura/formas-digitales-icono-autofactura-captura-cliente-1.png'
import icon_3 from '../../images/icons/autofactura/formas-digitales-icono-validador-3-3.png'
import icon_4 from '../../images/icons/autofactura/formas-digitales-icono-autofactura-previsualizacion-1.png'
import icon_5 from '../../images/icons/autofactura/formas-digitales-icono-autofactura-comprobantes-1.png'
import icon_6 from '../../images/icons/autofactura/formas-digitales-icono-autofactura-personalizar-1.png'

const AutoFactura = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <h2 className="uk-heading-bullet">¡Deja que tus clientes lo hagan!</h2>
            <p>¿Comercializas productos o servicios por medio de puntos de ventas como cadenas comerciales, comida rápida, restaurantes, gasolineras, etc.?</p>
            <h2 className="uk-heading-bullet">Portal fácil y rápido</h2>
            <div className="uk-child-width-1-3@s uk-text-center uk-light" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card; row: false">
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                        <img src={icon_1} alt="" />
                        <p>Tu CLIENTE recibe un ticket, con Número de Referencia y Token.</p>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                        <img src={icon_2} alt="" />
                        <p>Tu CLIENTE ingresa al portal y captura.</p>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                        <img src={icon_3} alt="" />
                        <p>Tu CLIENTE captura y confirma tus datos fiscales y su correo electrónico.</p>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                        <img src={icon_4} alt="" />
                        <p>Se muestra una previsualización de la compra de tu CLIENTE.</p>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                        <img src={icon_5} alt="" />
                        <p>Se envian los comprobantes a tu CLIENTE por correo electrónico o se descarga al momento en PDF y XML.</p>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-background-gradient uk-light uk-border-rounded uk-box-shadow-small uk-card-hover">
                        <img src={icon_6} alt="" />
                        <p>Podrás personalizar: logotipo y tipo de letra.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);


export default AutoFactura;