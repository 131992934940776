import benefit_1 from '../../images/icons/portal_benefits/formas-digitales-icono-acceso-addendador.png'
import benefit_2 from '../../images/icons/portal_benefits/formas-digitales-icono-validador.png'
import benefit_3 from '../../images/icons/portal_benefits/formas-digitales-icono-generador-pdf.png'
import benefit_4 from '../../images/icons/portal_benefits/formas-digitales-icono-descarga-masiva.png'
import benefit_5 from '../../images/icons/portal_benefits/formas-digitales-icono-recibo-nomina.png'
import benefit_6 from '../../images/icons/portal_benefits/formas-digitales-icono-desde-cualquier-lado.png'
import benefit_7 from '../../images/icons/portal_benefits/formas-digitales-icono-manejo-sucursales.png'
import benefit_8 from '../../images/icons/portal_benefits/formas-digitales-icono-plantillas.png'
import benefit_9 from '../../images/icons/portal_benefits/formas-digitales-icono-navegacion-segura.png'
import benefit_10 from '../../images/icons/portal_benefits/formas-digitales-icono-catalogos.png'
import benefit_11 from '../../images/icons/portal_benefits/formas-digitales-icono-sat.png'
import benefit_12 from '../../images/icons/portal_benefits/formas-digitales-icono-reportes-excel.png'
import benefit_13 from '../../images/icons/portal_benefits/formas-digitales-icono-emitir.png'
import benefit_14 from '../../images/icons/portal_benefits/formas-digitales-icono-catalogos-clientes.png'
import benefit_15 from '../../images/icons/portal_benefits/formas-digitales-icono-soporte-tecnico.png'
import benefit_16 from '../../images/icons/portal_benefits/formas-digitales-icono-prefactura.png'
import benefit_17 from '../../images/icons/portal_benefits/formas-digitales-icono-envio-correo.png'
import benefit_18 from '../../images/icons/portal_benefits/formas-digitales-icono-cancelacion.png'

const benefits_left = [
    { id: 'lb_1', img: benefit_1, desc: 'Acceso a portal Addendador.' },
    { id: 'lb_2', img: benefit_3, desc: 'Generador de PDF.' },
    { id: 'lb_3', img: benefit_5, desc: 'Emitir recibo de nómina.' },
    { id: 'lb_4', img: benefit_7, desc: 'Manejo de sucursales.' },
    { id: 'lb_5', img: benefit_9, desc: 'Navegación segura y amigable.' },
    { id: 'lb_6', img: benefit_10, desc: 'Retenciones.' },
    { id: 'lb_7', img: benefit_12, desc: 'Reportes en Excel y PDF.' },
    { id: 'lb_8', img: benefit_14, desc: 'Catálogos de clientes, productos e impuestos, etc.' },
    { id: 'lb_9', img: benefit_16, desc: 'Prefacturas.' },
    { id: 'lb_10', img: benefit_18, desc: 'Consultar peticiones nuevo esquema de cancelación.' }
]
const benefits_right = [
    { id: 'rb_1', img: benefit_2, desc: 'Validador XML.' },
    { id: 'rb_2', img: benefit_4, desc: 'Descarga masiva*.' },
    { id: 'rb_3', img: benefit_6, desc: 'Personaliza tus facturas con tu logotipo y colores corporativos.' },
    { id: 'rb_4', img: benefit_8, desc: 'Elabora tus documentos desde cualquier equipo con internet.' },
    { id: 'rb_5', img: benefit_9, desc: 'Complementos fiscales solicitados por el SAT.' },
    { id: 'rb_6', img: benefit_11, desc: 'Importación masiva de clientes y productos.' },
    { id: 'rb_7', img: benefit_13, desc: 'Puedes emitir tus documentos desde tu dispositivo móvil.' },
    { id: 'rb_8', img: benefit_15, desc: 'Soporte técnico ilimitado y sin costo.' },
    { id: 'rb_9', img: benefit_17, desc: 'Envío por correo de tu documento a más de 10 destinatarios.' },
]


const Portal = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <h2 className="uk-heading-bullet">Tenemos paquetes de acuerdo a tus necesidades</h2>
            <div className="uk-child-width-1-2@s" uk-grid={"true".toString()}>
                <div className="uk-width-1-1"><p>¿Qué tipo de comprobantes fiscales puedo emitir?</p></div>
                <div>
                    <ul className="uk-list uk-padding">
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Carta Porte</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Factura Electrónica</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Factura Global</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Nota de Cargo</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Nota de Crédito</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Recibo de Arrendamiento</li>
                    </ul>
                </div>
                <div>
                    <ul className="uk-list uk-padding">
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Recibo de Donativo</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Recibo de Honorarios</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Recibo de Nómina</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Recibo de Pago</li>
                        <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Retención e Información de Pagos</li>
                    </ul>
                </div>
            </div>
            <h2 className="uk-heading-bullet">Beneficios</h2>
            <div className="uk-child-width-1-2@s uk-grid-collapse" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card; row: false">
                <div>
                    <table className="uk-table uk-table-responsive uk-table-striped uk-table-divider uk-table-small uk-table-middle">
                        <tbody>
                            <Benefits benefits={benefits_left} />
                        </tbody>
                    </table>
                </div>
                <div>
                    <table className="uk-table uk-table-responsive uk-table-striped uk-table-divider uk-table-middle">
                        <tbody>
                            <Benefits benefits={benefits_right} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
);

function Benefits(props) {
    const items = props.benefits.map((benefit) =>
        <tr key={benefit.id}>
            <td><img src={benefit.img} alt="" /></td>
            <td>{benefit.desc}</td>
        </tr>
    )
    return items
}

export default Portal;