const DescargaCFDIPlus = () =>(
    <div className="uk-container uk-padding-large uk-text-justify">
        <p>
            Nuestro aplicativo de escritorio le permite descargar CFDI´s directamente del SAT. Administra los XML, genera PDF´s, exporta la información de los CFDI´s a Excel 
            y valida el estatus del comprobante (Vigente|Cancelado) y concilia la información de los CFDI´s que genera su sistema de facturación vs los CFDI´s del SAT.
        </p>
        <h3 className="uk-text-bold">Beneficios</h3>
        <ul>
            <li>Descargas por rango de fechas y distintos filtros.</li>
        </ul>
        <h5 className="uk-text-bold">Podrán seleccionar todos o por secciones; reportes con:</h5>
        <ul>
            <li>Genérico</li>
            <li>Nómina</li>
            <li>Edos - Efos</li>
            <li>Conceptos</li>
            <li>Pagos</li>
            <li>Cancelados</li>
            <li>Duplicados</li>
            <li>Diferencias de XML del SAT - ERP</li>
            <li>Si tienes XML te puedes hacer un reporte - excel</li>
            <li>Se puede verificar un XML para saber quien lo timbro</li>
            <li>Podrás consultar los XML recibidos</li>
        </ul>
    </div>
);

export default DescargaCFDIPlus;