import axios from 'axios';

const Aviso = () => (

    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <p>FORMAS CONTINUAS DE GUADALAJARA S.A DE C.V. en adelante FORMAS DIGITALES con domicilio en Avenida. Periférico. Poniente. Manuel Gómez Morin #7301,Ciudad Granja, 45010 Zapopan, Jalisco; es responsable de los datos personales que recaba por sí mismo y en respeto al derecho de privacidad y a la autodeterminación informativa de las personas en cumplimiento con lo dispuesto en la Ley de Protección de Datos Personales en Posesión de Particulares y su reglamento, atendiendo la protección de los datos personales en los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, adicionalmente prestamos especial atención a nuestro deber de Confidencialidad y Seguridad durante las diversas etapas de tratamiento.</p>
            <h3>MEDIOS DISPONIBLES DE COMUNICACIÓN</h3>
            <p>FORMAS DIGITALES ha nombrado a un comité técnico multidisciplinario para la protección de datos personales que son tratados bajo su control, quienes han de cumplir y hacer cumplir al interior de la organización y sus subsidiarias la Ley Federal de Protección de Datos Personales en Posesión de Particulares en adelante la Ley, así como su reglamento y lineamientos del Aviso de Privacidad. Para ello pone a la disposición de los titulares los siguientes datos de contacto mediante los cuales recibiremos cualquier solicitud para limitar el uso o divulgación de sus datos personales, aclaración o quejas.</p>
            <p>Correo electrónico: <a href="mailto:contacto@formasdigitales.com.mx">contacto@formasdigitales.com.mx</a></p>
            <p>Domicilio: en Ignacio Avenida. Periférico. Poniente. Manuel Gómez Morin #7301, Ciudad Granja, 45010 Zapopan, Jalisco Teléfono: 3337771677</p>
            <p>Formato disponible para cumplimiento de derechos ARCO <a href="https://s3.us-west-2.amazonaws.com/grupoforcogsa.mx/resourses/formasdigitales/descargas/FORM-solicitud_derechos_arco_ip.pdf" target={'_blank'}>FORM-solicitud_derechos_arco_ip.pdf <span uk-icon="icon: file-pdf;"></span></a></p>
            <p>Se establecen y mantienen para su tratamiento medidas de seguridad administrativas, físicas y técnicas apegadas a estándares internacionales y buenas prácticas que garantizan la calidad de los datos personales tomando en cuenta los riesgos existentes, las consecuencias para los titulares, la naturaleza del dato, los principios de la ley y el desarrollo tecnológico. Estas medidas no son menores a las utilizadas en otra información de la organización.</p>
            <h3>Datos Personales</h3>
            <p>FORMAS DIGITALES recaba y da tratamiento a los siguientes datos personales de manera lícita, siempre y cuando usted no manifieste objeción alguna en el momento que nos proporciona sus datos personales directamente, o bien declare su oposición posterior con solo enviarnos en cualquier momento un escrito libre a la dirección de correo electrónico o domicilio provistos en la sección previa, que exprese su deseo de revocar su consentimiento, al cual daremos respuesta a la brevedad indicándole, de ser el caso, el período en que las leyes y/o regulaciones nos exigen conservar algunos datos, mientras que el resto serán bloqueados y programados para su posterior cancelación.</p>
            <table className="uk-table">
                <thead>
                    <tr>
                        <td>Dato personal</td>
                        <td>Finalidad</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Nombre completo</td>
                        <td>Utilizado para contacto en la ejecución de proyectos, actividades comerciales, recomendaciones o para fines de facturación.</td>
                    </tr>
                    <tr>
                        <td>Correo electrónico</td>
                        <td>Utilizado para fines de comunicación durante la ejecución de proyectos o para fines comerciales o de recomendación.</td>
                    </tr>
                    <tr>
                        <td>Teléfono comercial</td>
                        <td>Utilizado para fines de comunicación durante la ejecución de proyectos o para facturación y relaciones comerciales.</td>
                    </tr>
                    <tr>
                        <td>Teléfono celular personal</td>
                        <td>Utilizado para fines de comunicación durante la ejecución de proyectos o para facturación y relaciones comerciales.</td>
                    </tr>
                    <tr>
                        <td>Constancia de situación fiscal</td>
                        <td>Utilizado como documento oficial por el cual la persona física o moral puede conocer el estatus que tienen ante el SAT</td>
                    </tr>
                    <tr>
                        <td>RFC (Registro Federal de Contribuyentes)</td>
                        <td>Recabado cuando la facturación se realiza a nombre de una persona con actividad empresarial.</td>
                    </tr>
                    <tr>
                        <td>Nombre o razón social</td>
                        <td>Recabado para identificación de nombre o razón de una persona con actividad empresarial.</td>
                    </tr>
                    <tr>
                        <td>CP (Código postal)</td>
                        <td>Recabado con el fin de validar el domicilio que tiene registrado la persona</td>
                    </tr>
                    <tr>
                        <td>CURP (Clave Única de Registro de Población)</td>
                        <td>Recabado con el fin de identificación extra de una persona.</td>
                    </tr>
                    <tr>
                        <td>Domicilio fiscal</td>
                        <td>Recabado cuando la facturación se realiza a nombre de una persona con actividad empresarial.</td>
                    </tr>
                    <tr>
                        <td>e.firma (firma electrónica)</td>
                        <td>Utilizado para fin de firma de contrato, carga de información para firma.</td>
                    </tr>
                    <tr>
                        <td>CSD (Certificado de Sello Digital)</td>
                        <td>Utilizad para fin de sellado de comprobante.</td>
                    </tr>
                </tbody>
            </table>
            <p>Fundamento para el tratamiento de datos personales.</p>
            <p>El tratamiento de sus datos personales se realiza con fundamento en los artículos 17, primer párrafo, y 21, fracción XXIV de la Ley Federal de Transparencia y Acceso a la información Pública , última reforma publicada en el Diario Oficial de la Federación (DOF) el 20 de mayo de 2021; 89, fracciones XXIV y XXVI de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, publicada en el DOF el 26 de enero de 2017 y 30 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares publicada en el DOF el 5 de julio de 2010.</p>
            <h3>Finalidades Primarias</h3>
            <p>De acuerdo a los art. 40,41 y 42 del reglamento, los anteriores datos personales son considerados por FORMAS DIGITALES como necesarios para establecer la relación jurídica con la organización y serán utilizados para las siguientes finalidades:</p>
            <ul>
                <li>Generación de comprobantes fiscales.</li>
                <li>Cumplimiento de obligaciones fiscales.</li>
                <li>Creación de cuenta para generación de comprobantes fiscales.</li>
                <li>Uso de e.firma para la firma de contratos y usos propios de la cuenta encontrados dentro del portal de facturación <a href="https://www.portal.facturacfdi.mx/" target="_blank">https://www.portal.facturacfdi.mx/</a></li>
            </ul>
            <h3>Finalidades secundarias</h3>
            <p>La finalidad secundaria es aquella que no es necesaria para el desarrollo de una relación jurídica entre el Titular (usted) y el Responsable (nosotros), por lo cual usted puede indicarnos que no autoriza el tratamiento de sus datos personales en alguna o todas las que se mencionan en esta sección. Sus datos personales no son utilizados para ninguna finalidad secundaria, esto se refiere a que</p>
            <p><a href="https://s3.us-west-2.amazonaws.com/grupoforcogsa.mx/resourses/formasdigitales/descargas/FORM-solicitud_derechos_arco_ip.pdf" target={'_blank'}>FORM-solicitud_derechos_arco_ip.pdf <span uk-icon="icon: file-pdf;"></span></a></p>
            <h3>Transferencias de datos personales</h3>
            <p>Los datos personales anteriormente descritos pueden ser transferidos y tratados por los terceros abajo descritos. Todas las finalidades mencionadas están en los supuestos del art. 37 de la ley y en su apartado VII.</p>
            <table>
                <thead>
                    <tr>
                        <td>Tercero</td>
                        <td>Finalidad</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Autoridades gubernamentales mexicanas.</td>
                        <td>Cumplimiento de legislación aplicable.</td>
                    </tr>
                </tbody>
            </table>
            <p>Por los medios que se ponen a su disposición en el apartado “Medios disponibles de comunicación” con gusto recibiremos su solicitud de oposición o limitación al uso y tratamiento de sus datos personales. La información detallada del uso de los anteriores datos personales podrá ser provista bajo petición del titular por los mecanismos para ejercer los derechos ARCO y de limitación de uso descrito más adelante en la sección del mismo nombre. La información detallada de las personas físicas o morales a las cuales se les transfieren los datos personales podrá ser provista bajo petición del titular en los mecanismos previstos para ejercer los derechos ARCO.</p>
            <h3>Manejo De Datos Sensibles</h3>
            <p>Le informamos que no recabamos ni realizamos el tratamiento de datos personales de carácter sensible de nuestros clientes.</p>
            <h3>Plazos de Conservación</h3>
            <p>FORMAS DIGITALES procederá a cancelar y efectuar la supresión previo bloqueo de los datos personales 6 meses después de que cumplan con la finalidad que justificó su tratamiento de acuerdo al inventario de datos personales y procedimientos internos de FORMAS DIGITALES a la disposición del titular que podrán ser entregados bajo petición del titular en los mecanismos previstos para ejercer los derechos ARCO La cancelación de datos personales no será realizada cuando le sean aplicables los supuestos de los artículos 26 y 34 de la Ley.</p>
            <h3>Ejercicio De Los Derechos Arco</h3>
            <p>Con gusto daremos cumplimiento a la solicitud que recibamos del titular o de su representante legal por la cual requiera hacer uso de sus derechos al Acceso, Rectificación, Cancelación u Oposición (ARCO) de sus datos personales en posesión de FORMAS DIGITALES Recibiremos la solicitud con la siguiente información en documento físico o electrónico utilizando los medios que ponemos a su disposición descrita en el apartado “Medios disponibles de comunicación”.</p>
            <ul>
                <li>Nombre y domicilio, así como cualquier información complementaria que sirva para contactarle (correo electrónico y teléfono).</li>
                <li>Documentos que acrediten la identidad.</li>
                <li>Descripción concisa y clara que describa los derechos que se desean ejercer, así como los datos personales involucrados.</li>
                <li>En el caso de solicitar rectificación de datos personales, deberá acompañar la documentación que sustente la petición.</li>
                <li>Medio de respuesta de solicitud será confirmado al titular si la respuesta a la solicitud es procedente, por el mismo medio en el que fue recibida la solicitud.</li>
                <li>Medio de reproducción de los datos personales solicitados pueden ser mediante, USB, Solicitud impresa (entrega en físico), enviado por correo, debidamente llenada y firmada por el personal correspondiente.</li>
            </ul>
            <p>La respuesta a tu solicitud se le hará llegar dentro de los plazos que marca la Ley siempre y cuando no se encuentren bajo los supuestos de excepción del artículo 26 de la ley y 75 del reglamento. Con gusto proveemos de un formato básico para el cumplimiento de los derechos arco en la siguiente localización:</p>
            <p><a href="https://s3.us-west-2.amazonaws.com/grupoforcogsa.mx/resourses/formasdigitales/descargas/FORM-solicitud_derechos_arco_ip.pdf" target={'_blank'}>FORM-solicitud_derechos_arco_ip.pdf <span uk-icon="icon: file-pdf;"></span></a></p>
            <h3>Revocación Del Consentimiento</h3>
            <p>De acuerdo al artículo 21 del reglamento, en todo momento usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales con el fin de que dejemos de hacer uso y transferencia de los mismos mediante la utilización de los mecanismos provistos para el cumplimiento de los derechos ARCO para ello en este documento en el apartado de “Medios disponibles de comunicación”, siempre y cuando esta solicitud no entre en conflicto con las consideraciones mencionadas en el artículo 26 de la ley. Tendremos un plazo máximo de 10 días hábiles para responder su solicitud y le informaremos de la procedencia de la misma vía correo electrónico o telefónico a los datos registrados en su solicitud.</p>
            <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición).</p>
            <h3>Opciones Y Medios Para La Limitación Del Uso O Divulgación De Sus Datos Personales</h3>
            <p>Para cualquier otra necesidad de limitar los datos personales distinta a los provistos por los derechos ARCO y por la revocación de consentimiento, el responsable de manejar sus datos personales provee al titular de los mismos mecanismos de comunicación mencionados en el apartado “MEDIOS DISPONIBLES DE COMUNICACIÓN”, con el fin de proveerle apoyo y asistencia para resolver cualquier requerimiento especial de limitación de uso o divulgación de sus datos personales.</p>
            <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:</p>
            <p>Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para más información sobre este registro, usted puede consultar el portal de Internet de la PROFECO (Procuraduría Federal del Consumidor), o bien ponerse en contacto directo con ésta; o bien, el Registro Público de Usuarios, (CONDUSEF - Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros).</p>
            <h3>Uso De Cookies, Web Beacons Y Otras Tecnologias De Rastreo</h3>
            <p>El responsable de manejar sus datos personales notifica al titular que en su página web no utiliza tecnologías para el rastreo y obtención de datos personales conocidas como cookies o web beacons u otras tecnologías similares para el rastreo del titular. Los datos obtenidos a través de formulario se utilizarán solo para contactar a la persona que introduce sus datos personales en el mismo. Se asume que la persona, al introducir sus datos personales en el formulario por su propia voluntad da su consentimiento tácito a su uso para los fines de contacto e inicio de una relación de negocios.</p>
            <h3>Modificaciones Al Aviso De Privacidad</h3>
            <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Estas modificaciones estarán disponibles al público a través de los medios descritos en este documento en el apartado “MEDIOS DISPONIBLES DE COMUNICACIÓN” y en nuestras oficinas.</p>
            <h3>Autoridad Competente Ante Quien Puede Presentar Sus Quejas Y Denuncias Por El Tratamiento Indebido De Sus Datos Personales</h3>
            <p>Si considera que su derecho a la protección y tratamiento adecuado de sus datos personales ha sido afectado por conductas indebidas de empleados o de la empresa con gusto podemos atenderlo en los datos proporcionados en “medios disponibles de comunicación” y/o <a href="matilto:contacto@formasdigitales.com.mx">contacto@formasdigitales.com.mx</a> para una atención oportuna, en caso de considerar que nuestra respuesta no es adecuada, puede solicitar asesoría Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) quien es el organismo autónomo responsable de la Protección de Datos Personales, los datos de contacto y material relacionado se pueden encontrar en el portal: <a href="https://home.inai.org.mx/">https://home.inai.org.mx/</a></p>
            <h3>Glosario</h3>
            <p><b>Aviso de Privacidad</b>: Documento físico, electrónico o en cualquier otro formato generado por el responsable que es puesto a disposición del titular, previo al tratamiento de sus datos personales, de conformidad con el artículo 15 de la presente Ley.</p>
            <p><b>Bases de datos</b>: El conjunto ordenado de datos personales referentes a una persona identificada o identificable.</p>
            <p><b>Bloqueo</b>: La identificación y conservación de datos personales una vez cumplida la finalidad para la cual fueron recabados, con el único propósito de determinar posibles responsabilidades en relación con su tratamiento, hasta el plazo de prescripción legal o contractual de éstas. Durante dicho periodo, los datos personales no podrán ser objeto de tratamiento y transcurrido éste, se procederá a su cancelación en la base de datos que corresponde.</p>
            <p><b>Consentimiento</b>: Manifestación de la voluntad del titular de los datos mediante la cual se efectúa el tratamiento de los mismos.</p>
            <p><b>Datos personales</b>: Cualquier información concerniente a una persona física identificada o identificable.</p>
            <p><b>Datos personales sensibles</b>: Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida puedan dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futura, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.</p>
            <p><b>Encargado</b>: La persona física o jurídica que sola o conjuntamente con otras trate datos personales por cuenta del responsable.</p>
            <p><b>Fuente de acceso público</b>: Aquellas bases de datos cuya consulta puede ser realizada por cualquier persona, sin más requisito que, en su caso, el pago de una contraprestación, de conformidad con lo señalado por el Reglamento de esta Ley.</p>
            <p><b>Ley</b>: Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Reglamento: Reglamento de la Ley Responsable: Persona física o moral de carácter privado que decide sobre el tratamiento de datos personales.</p>
            <p><b>Tercero</b>: La persona física o moral, nacional o extranjera, distinta del titular o del responsable de los datos.</p>
            <p><b>Titular</b>: La persona física a quien corresponden los datos personales.</p>
            <p><b>Tratamiento</b>: La obtención, uso, divulgación o almacenamiento de datos personales, por cualquier medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición de datos personales.</p>
            <p><b>Transferencia</b>: Transferencia es toda comunicación de datos personales dentro o fuera del territorio mexicano, realizada a persona distinta del titular, del responsable o del encargado (Ley General de Datos, art. 3, fracción XXXII).</p>
            <p><b>Remisión</b>: Es toda comunicación de datos personales realizada exclusivamente entre el responsable y encargado, dentro o fuera del territorio mexicano (Ley General de Datos, art. 3, fracción XXVII).</p>
        </div>
    </div>
);


export default Aviso;