import React from 'react'

class HeaderSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { title: props.title, background_pic: props.bg };
    }

    render() {
        return (
            <div className="uk-section-default">
                <div className="uk-section uk-light uk-background-cover" style={{ backgroundImage: `url(${this.state.background_pic})` }}>
                    <div className="uk-container">
                        <h1 className="uk-heading-divider uk-text-uppercase section-header">{this.state.title}</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderSection;