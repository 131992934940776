import tarjetas from '../images/tarjetas.png'
function TopBar() {
    return (
        <>
            <div className="uk-container-expand uk-background-topbar topbar-height uk-visible@s">
                <div className="uk-container topbar-height">
                    <nav className="uk-navbar-container uk-navbar-transparent topbar-height" uk-navbar={"true".toString()}>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav topbar-height uk-light">
                                <li className="uk-navbar-item"><a href="tel:3337771677" className="uk-text-white"><span uk-icon="receiver"></span> 3337771677</a></li>
                                <li className="uk-navbar-item"><a href="https://www.portal.facturacfdi.mx/" target="_blank" rel="noreferrer"><button className="uk-button topbar-buttons uk-button-small uk-border-rounded">Ingresar al portal</button></a></li>
                                <li className="uk-navbar-item"><a href="/precios"><button className="uk-button topbar-buttons uk-button-small uk-border-rounded">Contratar</button></a></li>
                                <li className="uk-navbar-item uk-logo"><img src={tarjetas} alt="tarjetas" /></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="uk-container-expand uk-background-topbar topbar-height-mobile uk-hidden@s">
                <div className="uk-container topbar-height">
                    <nav className="uk-navbar-container uk-navbar-transparent topbar-height" uk-navbar={"true".toString()}>
                        <div className="uk-navbar-left">
                            <ul className="uk-navbar-nav topbar-height uk-light">
                                <li className="uk-navbar-item"><a href="tel:3337771677" className="uk-text-white"><span uk-icon="receiver"></span> 3337771677</a></li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav topbar-height uk-light">
                                <li className="uk-navbar-item"><a href="https://www.portal.facturacfdi.mx/" target="_blank" rel="noreferrer"><button className="uk-button topbar-buttons uk-button-small uk-border-rounded">Iniciar sesión</button></a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}


export default TopBar;