import aspelLogo from '../../images/partners/logo-aspel.svg'
// products
import sae from '../../images/icons/partners/aspel/categoria_sae.svg'
import coi from '../../images/icons/partners/aspel/categoria_coi.svg'
import noi from '../../images/icons/partners/aspel/categoria_noi.svg'
import facture from '../../images/icons/partners/aspel/categoria_facture.svg'
import caja from '../../images/icons/partners/aspel/categoria_caja.svg'
import banco from '../../images/icons/partners/aspel/categoria_banco.svg'
import prod from '../../images/icons/partners/aspel/categoria_prod.svg'
import noi_ass from '../../images/icons/partners/aspel/categoria_noi_asistente.svg'
import adm from '../../images/icons/partners/aspel/categoria_adm.svg'
import conta from '../../images/icons/partners/aspel/categoria_conta_asistente.svg'
import espacio from '../../images/icons/partners/aspel/categoria_espacio.svg'
import servidor from '../../images/icons/partners/aspel/imagotipo-vertical-servidor-virtual.svg'

const products = [
    { name: 'SAE', icon: sae, desc: 'Controla el ciclo de todas las operaciones de compra y venta de tu empresa.', link: 'https://www.aspel.com.mx/sae?pag=beneficios'},
    { name: 'COI', icon: coi, desc: 'Procesa, integra y mantiene actualizada la información contable y fiscal de tu empresa.', link: 'https://www.aspel.com.mx/coi?pag=beneficios'},
    { name: 'NOI', icon: noi, desc: 'Automatiza y controla todos los procesos de la Nómina y el Capital humano.', link: 'https://www.aspel.com.mx/noi?pag=beneficios' },
    { name: 'FACTURE', icon: facture, desc: 'Genera Facturas Electrónicas 3.3 sin importar tu profesión o giro comercial.', link: 'https://www.aspel.com.mx/facture?pag=beneficios' },
    { name: 'CAJA', icon: caja, desc: 'Administra las operaciones de venta e inventarios de comercios, cajas, y cajeros.', link: 'https://www.aspel.com.mx/caja?pag=beneficios' },
    { name: 'BANCO', icon: banco, desc: 'Controla todos los movimientos de cuentas bancarias en moneda nacional y extranjera.', link: 'https://www.aspel.com.mx/banco?pag=beneficios' },
    { name: 'PROD', icon: prod, desc: 'Planea y controla los procesos de fabricación de tu empresa.', link: 'https://www.aspel.com.mx/prod?pag=beneficios' },
    { name: 'NOI ASISTENTE', icon: noi_ass, desc: 'Administra las incidencias de tu personal desde cualquier dispositivo móvil.', link: 'https://www.aspel.com.mx/noi-asistente?pag=beneficios' },
    { name: 'ADM', icon: adm, desc: 'Gestiona las principales operaciones de tu negocio desde la nube.', link: 'https://www.aspel.com.mx/adm?pag=beneficios' },
    { name: 'CONTA ASISTENTE', icon: conta, desc: 'Controla y registra tus movimientos financieros desde tu iPhone o iPad.', link: 'https://www.aspel.com.mx/conta-asistente?pag=beneficios' },
    { name: 'ESPACIO', icon: espacio, desc: 'Concentra y comparte desde la nube información contable, fiscal, y comercial.', link: 'https://www.aspel.com.mx/espacio?pag=beneficios' },
    { name: 'SERVIDOR VIRTUAL', icon: servidor, desc: 'Consulta y administra información de tu empresa desde cualquier lugar.', link: 'https://www.aspel.com.mx/servidor-virtual?pag=beneficios' }
]

const Aspel = () => (
    <div className="uk-container-expand" uk-height-viewport="expand: true">
        <div className="uk-container uk-padding-large uk-text-justify">
            <div className="uk-margin">
                <img src={aspelLogo} alt="aspel_logo" width="192" />
            </div>
            <div className="uk-margin">
                <p>Aspel es una empresa 100% mexicana líder en el mercado de software y soluciones administrativas. Automatiza los procesos de las micro, pequeñas y medianas empresas, favoreciendo la correcta toma de decisiones de manera sencilla, eficiente y productiva. Sus Sistemas facilitan el cumplimiento de las obligaciones fiscales electrónicas incluyendo facturación, contabilidad y nómina</p>
            </div>
            <h2 className="uk-heading-bullet">Productos</h2>
            <div className="uk-child-width-1-3@s" uk-grid={"true".toString()}>
                <Products products={products}/>
            </div>
        </div>
    </div>
);

function Products(props) {
    const items = props.products.map((product, i) =>
        <div key={i}>
            <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid={"true".toString()}>
                        <div className="uk-width-auto">
                            <img className="uk-border-circle" width="64" height="64" src={product.icon} alt={product.name}/>
                        </div>
                        <div className="uk-width-expand">
                            <h3 className="uk-card-title uk-margin-remove-bottom">{product.name}</h3>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body uk-padding-small">
                    <p>{product.desc}</p>
                </div>
            </div>
        </div>
    )
    return items
}


export default Aspel;