const DescargaCFDI = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <p>Descarga todos tus comprobantes directamente desde servicios del SAT.</p>
            <h2 className="uk-heading-bullet">Características principales</h2>
            <ul className="uk-list uk-padding">
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Soporte de CFDI’s versión 3.2 y 3.3</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Verifica estatus de CFDI’s (Activo / Cancelado)</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Descarga tus CFDi de forma masiva en formato XML (emitidos y recibidos).</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Genera reporte en formato XLS</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Genera PDF de los documentos descargados.</li>
                <li><span className="uk-margin-small-right green-text" uk-icon="check"></span>Mesa de Servicio con especialistas de soporte técnico vía telefónica, email, chat o remoto disponible de Lunes a Viernes de 8:30 a.m. a 07:00 p.m.</li>
            </ul>
        </div>
    </div>
);


export default DescargaCFDI;