import React, { Component } from 'react'

import logoFD_w from '../images/logoFD_w.svg'
import amexipacLogo from '../images/amexipac.svg'
import satPacLogo from '../images/pac_sat_w.svg'
import logo40aniv from '../images/40_aniversario_logo.png'
//Christmas logo
//import logoFD_w from '../images/logoFD_w_xmas.png'

class MainMenu extends Component {

    render() {
        let url = ""
        return (
            <div uk-sticky="sel-target: .uk-background-main-menu">
                <div className="uk-container-expand uk-background-main-menu uk-light uk-hidden@s">
                    <div className="uk-navbar-container uk-navbar-transparent" uk-navbar={"true".toString()}>
                        <div className="uk-navbar-left">
                            <a href={url} className="uk-navbar-item uk-logo"><img src={logoFD_w} width="128" alt="logoFD_w" /></a>
                        </div>
                        <div className="uk-navbar-right">
                            <button className="uk-navbar-toggle uk-text-white" uk-navbar-toggle-icon={"true".toString()} uk-toggle="target: #offcanvas-menu"></button>
                        </div>
                    </div>
                </div>
                <div className="uk-container-expand uk-background-main-menu uk-light uk-visible@s">
                    <div className="uk-container">
                        <nav className="uk-navbar-container uk-navbar-transparent" uk-navbar={"true".toString()} id="mainMenu">
                            <div className="uk-navbar-left">
                                <ul className="uk-navbar-nav">
                                    <a className="uk-navbar-item uk-logo" href="/"><img src={logoFD_w} width="192" alt="logoFD_w" /> </a>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/">Inicio</a></li>
                                    <li>
                                        <a href={url} className="uk-text-white">Soluciones</a>
                                        <div className="uk-navbar-dropdown uk-background-submenu uk-border-rounded uk-padding-remove">
                                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                                <li><a href="/soluciones/portal-de-facturacion-y-nomina-en-la-nube">Portal de facturación y nómina en la nube</a></li>
                                                <li><a href="/soluciones/timbrado-web-service">Timbrado Web Service</a></li>
                                                <li><a href="/soluciones/conector-digital">Conector Digital</a></li>
                                                <li><a href="/soluciones/distribuidores">Distribuidores</a></li>
                                                <li><a href="/soluciones/portal-autofactura">Portal Autofactura</a></li>
                                                <li><a href="/soluciones/validador-xml">Validador XML</a></li>
                                                <li><a href="/soluciones/addendador">Addendador</a></li>
                                                <li><a href="/soluciones/central-forsedi">Central Forsedi</a></li>
                                                <li><a href="/soluciones/descarga-cfdi">Descarga CFDI</a></li>
                                                <li><a href='/soluciones/descarga-cfdi-plus'>Descarga CFDI Plus</a></li>
                                                <li>
                                                    <a href="#" className="uk-text-white">Partners</a>
                                                    <div className="uk-navbar-dropdown uk-background-submenu uk-border-rounded uk-padding-remove" uk-dropdown="pos: right-center; delay-show: 300; delay-hide: 300">
                                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                                            <li><a href="/partners/aspel" className="uk-text-white">Aspel</a></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><a href="/precios" className="uk-text-white">Precios</a></li>
                                    <li>
                                        <a href="/nosotros" className="uk-text-white">Nosotros</a>
                                        <div className="uk-navbar-dropdown uk-background-submenu uk-border-rounded uk-padding-remove">
                                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                                <li><a href="/bolsa-de-trabajo" className="uk-text-white">Bolsa de Trabajo</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><a href="/contacto" className="uk-text-white">Contacto</a></li>
                                    <li>
                                        <a href={url} className="uk-text-white">Soporte</a>
                                        <div className="uk-navbar-dropdown uk-background-submenu uk-border-rounded uk-padding-remove">
                                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                                <li><a href="/enlaces-utiles" className="uk-text-white">Enlaces Útiles</a></li>
                                                <li><a href="/preguntas-frecuentes" className="uk-text-white">Preguntas Frecuentes</a></li>
                                                <li><a href="https://forsedi.facturacfdi.mx/developers/" target="_blank" rel="noreferrer" className="uk-text-white">Sitio Desarrolladores</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* <li><a href="https://www.amexipac.org/" target="_blank" rel="noreferrer" className="uk-text-white"><img src={amexipacLogo} width="64" alt="amexipac" /></a></li> */}
                                    <li><a href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/pac_formasdigitales.htm" target="_blank" rel="noreferrer" className="uk-text-white"><img src={satPacLogo} width="42" alt="pacsat" /></a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainMenu;