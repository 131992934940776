import React from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
// banner slides
import slide_1 from '../images/formas-digitales-fondo-banner-facturacion-nube.png'
import slide_2 from '../images/formas-digitales-fondo-banner-1.png'
import slide_3 from '../images/formas-digitales-fondo-banner-soporte-tecnico.png'
import slide_4 from '../images/carta-porte_formas.png'
import slide_5 from '../images/40_aniversario.png'
// app logos
import addendador from '../images/apps/addendador.svg'
import autofactura from '../images/apps/autofactura.svg'
import centralforsedi from '../images/apps/centralforsedi.svg'
import conector from '../images/apps/conector.svg'
import descargacfdi from '../images/apps/descargacfdi.svg'
import developers from '../images/apps/developers.svg'
import portalcfdi from '../images/apps/portalcfdi.svg'
import validador from '../images/apps/validador.svg'
import descargacfdiplus from '../images/apps/Descarga CFDI+.svg'
// icons benefits
import seguridad_icon from '../images/icons/formas-digitales-icono-seguridad.png'
import soporte_icon from '../images/icons/formas-digitales-icono-soporte-tecnico.png'
import unlimited_icon from '../images/icons/formas-digitales-icono-estaras-actualizado.png'
import personalized_icon from '../images/icons/formas-digitales-icono-personalizacion-de-documentos.png'
import updated_icon from '../images/icons/formas-digitales-icono-estaras-actualizado.png'
import solutions_icon from '../images/icons/formas-digitales-icono-soluciones-especializadas.png'
import easy_icon from '../images/icons/formas-digitales-icono-facil-rapido.png'
// partners logos
import aspelLogo from '../images/partners/logo-aspel.svg'
import crolLogo from '../images/partners/logo-crol.svg'
const SECRET_KEY_reCAPTCHA = '6Lcoyt4UAAAAAJfMe78YBeKfwp2xDD6AvUgZUYZt'
const FORM_ENDPOINT = 'https://forsedi.facturacfdi.mx/AppConectores/partners/addClient'
const apps = [
    {id: 1, logo: descargacfdiplus, desc: 'Aplicativo de escritorio que permite descargar CFDI´s directamente del SAT, permitiendo crear PDF´s, reportes y diferencias entre el SAT y el ERP.',url:'/soluciones/descarga-cfdi-plus',target:'_self'},
    { id: 2, logo: addendador, desc: 'Agrega fácilmente información que te interesa comercialmente a tus CDFI’s sin que sea relevante en términos fiscales.', url: '/soluciones/addendador', target: '_self' },
    { id: 3, logo: autofactura, desc: '¿Comercializas productos o servicios por medio de puntos de ventas como cadenas comerciales, comida rápida, restaurantes, gasolineras, etc.?', url: '/soluciones/portal-autofactura', target: '_self' },
    { id: 4, logo: centralforsedi, desc: 'Descarga de manera masiva todos tus CFDI’s timbrados por Formas Digitales® desde un solo sitio.', url: '/soluciones/central-forsedi', target: '_self' },
    { id: 5, logo: conector, desc: 'Si tú ya cuentas con tu propio ERP o sistema administrativo; conectamos la facturación electrónica (CFDI) con las últimas disposiciones fiscales del SAT.', url: '/soluciones/conector-digital', target: '_self' },
    { id: 6, logo: descargacfdi, desc: 'Descarga todos tus comprobantes directamente desde servicios del SAT.', url: '/soluciones/descarga-cfdi', target: '_self' },
    { id: 7, logo: developers, desc: 'Sitio con documentación que te ayudará a lo largo de todo el proceso de integración con nuestro Web Service para el timbrado de tu facturación electrónica.', url: 'https://forsedi.facturacfdi.mx/developers/', target: '_blank' },
    { id: 8, logo: portalcfdi, desc: 'Elabora tus documentos desde cualquier equipo que tenga acceso internet. ¡Rápido y Seguro!', url: '/soluciones/portal-de-facturacion-y-nomina-en-la-nube', target: '_self' },
    { id: 9, logo: validador, desc: 'Verifica los comprobantes fiscales electrónicos que recibes de la manera más sencilla. Recuerda que el único esquema válido de comprobación es la factura electrónica CFDI.', url: '/soluciones/validador', target: '_self' }
];

const benefits = [
    { title: 'SEGURIDAD Y PROTECCIÓN', icon: seguridad_icon, desc: 'Ofrecemos una amplia gama de servicios para todo tipo de contribuyente.', icon_width: '24', icon_height: '24' },
    { title: 'SOPORTE TÉCNICO', icon: soporte_icon, desc: 'Contamos con soporte técnico completamente gratuito a través de chat, correo o vía telefónica.', icon_width: '32', icon_height: '32' },
    { title: 'SIN RESTRICCIONES', icon: unlimited_icon, desc: 'Tendrás acceso seguro a tus facturas en cualquier momento y en cualquier lugar.', icon_width: '32', icon_height: '32' },
    { title: 'PERSONALIZACIÓN', icon: personalized_icon, desc: 'Obtén documentos completamente personalizados con la imagen de tu empresa, puedes agregar tu logotipo y colores corporativos.', icon_width: '32', icon_height: '32' },
    { title: 'ESTARÁS ACTUALIZADO', icon: updated_icon, desc: 'Con las nuevas tecnologías la facturación electrónica nunca fue tan fácil.', icon_width: '32', icon_height: '32' },
    { title: 'SOLUCIONES ESPECIALIZADAS', icon: solutions_icon, desc: 'Nuestra amplia gama de servicios nos hacen accesibles para todos los contribuyentes sin importar su actividad.', icon_width: '32', icon_height: '32' },
    { title: 'FÁCIL Y RÁPIDO', icon: easy_icon, desc: 'Ofrecemos un mayor control sobre la contabilidad de tu negocio.', icon_width: '32', icon_height: '32' }
]

const url = "";
//Declare variables

const Home = () => (
    <div>
        <SlideShow />
        <SolucionesSection />
        <SolucionesEspecializadasSection url={url} />
        <ContratarSection />
        <OurPartners />

    </div>
);

const AddAdvice = () => {

    document.addEventListener("DOMContentLoaded", function (e) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = "UIkit.modal($('#modal_advice')).show()";
        document.body.appendChild(s);
    });

    return (
        <div id='modal_advice' className='uk-flex-around uk-flex-column-reverse uk-flex'>
            <div className='uk-modal-dialog uk-modal-body uk-border-rounded uk-width-1-2'>
                <button className='uk-modal-close-default' type='button'>X</button>
                <div className='uk-modal-header'>
                    <h2 className='uk-modal-title'>Pr&oacute;rroga Carta Porte 3.0</h2>
                </div>
                <div className='uk-modal-body'>
                    <p>
                        El SAT hizo p&uacute;blico el d&iacute;a 20 de octubre la fecha oficial del inicio del complemento Carta porte 3.0.
                    </p>
                    <p className='uk-text-bold uk-margin-remove'>Periodo de convivencia:</p>
                    <p className='uk-margin-remove'>Con el objetivo de que puedan familiarizarse con la nueva versi&oacute;n, a&uacute;n podr&aacute;n seguir emitiendo facturas con carta porte 2.0 hasta el <b>31 de diciembre del 2023.</b></p>
                    <p className='uk-text-bold uk-margin-remove-bottom'>Obligatoriedad:</p>
                    <p className='uk-margin-remove'>A partir del <b>1 de enero del 2024,</b> la versi&oacute;n v&aacute;lida para los comprobantes con complemento de carta porte ser&aacute; la versión 3.0.</p>
                    <br />
                    <a href='https://twitter.com/SATMX/status/1715471049146397121?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Etweet&utm_source=All&utm_campaign=Ventana+de+Mantenimiento+-+DRP&utm_medium=email' target='_blank'>COMUNICADO OFICIAL</a>
                </div>
            </div>
        </div>

    );
}
const SlideShow = () => (
    <div className="uk-position-relative uk-visible-toggle" tabIndex="-1" uk-slideshow="animation: push; autoplay: true; pause-on-hover: false; autoplay-interval: 6000" id="top">
        <ul className="uk-slideshow-items" uk-height-viewport="offset-top: 100">
            <li>
                <img src={slide_5} width="50%" className='uk-cover-container uk-position-top-center ' type="image/png" />
            </li>
            <li>
                <img src={slide_4} uk-cover={"true".toString()} type="image/png" />
                <div className='carousel-inner'></div>
                <div className='uk-position-center-left uk-margin-homeslide uk-position-small uk-text-left'>
                    <h1 className="carousel-h1" uk-slideshow-parallax="y: -50,0,0; opacity: 1,1,0">Carta Porte 3.1</h1>
                    <p className="carousel-p" uk-slideshow-parallax="y: 100,0,0; opacity: 1,1,0">Preparate para la nueva versi&oacute;n <br /> del complemento</p>
                    <a href="https://forsedi.facturacfdi.mx/developers/complemento-cartaporte31" target='_blank' className="uk-button uk-button-primary uk-border-rounded-more" uk-slideshow-parallax="y: 200,0,0; opacity: 1,1,0">M&aacute;s informaci&oacute;n<span uk-icon="icon: arrow-right; ratio: 1.5"></span></a>
                </div>
            </li>
            <li>
                <img src={slide_1} alt="" uk-cover={"true".toString()} />
                <div className="carousel-inner"></div>
                <div className="uk-position-center-left uk-margin-homeslide uk-position-small uk-text-left">
                    <h1 className="carousel-h1" uk-slideshow-parallax="y: -50,0,0; opacity: 1,1,0">facturaci&oacute;n y n&oacute;mina en la nube</h1>
                    <p className="carousel-p" uk-slideshow-parallax="y: 50,0,0; opacity: 1,1,0">Navegaci&oacute;n segura y en cualquier lugar.</p>
                    <p className="carousel-p uk-text-meta-fd" uk-slideshow-parallax="y: 50,0,0; opacity: 1,1,0">Pru&eacute;balo Sin Costo por 15 d&iacute;as.</p>
                    <a href="/soluciones/portal-de-facturacion-y-nomina-en-la-nube" className="uk-button uk-button-primary uk-border-rounded-more uk-animation-slide-left" uk-slideshow-parallax="y: 200,0,0; opacity: 1,1,0">Descubre c&oacute;mo <span uk-icon="icon: arrow-right; ratio: 1.5"></span></a>
                </div>
            </li>
            <li>
                <img src={slide_2} alt="" uk-cover={"true".toString()} />
                <div className="carousel-inner"></div>
                <div className="uk-position-center-left uk-margin-homeslide uk-position-small uk-text-left">
                    <h1 className="carousel-h1" uk-slideshow-parallax="y: -50,0,0; opacity: 1,1,0">as&iacute; de f&aacute;cil</h1>
                    <p className="carousel-p" uk-slideshow-parallax="y: 100,0,0; opacity: 1,1,0">Facturaci&oacute;n Simple e Inteligente.</p>
                    <p className="carousel-p uk-text-meta-fd" uk-slideshow-parallax="y: 100,0,0; opacity: 1,1,0">¡R&aacute;pido y Seguro!</p>
                    <a href="/precios" className="uk-button uk-button-primary uk-border-rounded-more" uk-slideshow-parallax="y: 200,0,0; opacity: 1,1,0">Contratar ahora <span uk-icon="icon: arrow-right; ratio: 1.5"></span></a>
                </div>
            </li>
            <li>
                <img src={slide_3} alt="" uk-cover={"true".toString()} />
                <div className="carousel-inner"></div>
                <div className="uk-position-center-left uk-margin-homeslide uk-position-small uk-text-left">
                    <h1 className="carousel-h1" uk-slideshow-parallax="y: -50,0,0; opacity: 1,1,0">soporte t&eacute;cnico</h1>
                    <p className="carousel-p" uk-slideshow-parallax="y: 100,0,0; opacity: 1,1,0">Ilimitado y Sin Costo.</p>
                    <p uk-slideshow-parallax="y: 100,0,0; opacity: 1,1,0">
                        <span className="uk-banner-icons" uk-icon="icon: mail; ratio: 2" uk-tooltip="title: Email; pos: top"></span>
                        <span className="uk-banner-icons" uk-icon="icon: receiver; ratio: 2" uk-tooltip="title: Teléfono; pos: top"></span>
                        <span className="uk-banner-icons" uk-icon="icon: comments; ratio: 2" uk-tooltip="title: Chat; pos: top"></span>
                        <span className="uk-banner-icons" uk-icon="icon: link; ratio: 2" uk-tooltip="title: Remoto; pos: top"></span>
                    </p>
                    <a href="/soluciones/portal-de-facturacion-y-nomina-en-la-nube" className="uk-button uk-button-primary uk-border-rounded-more" uk-slideshow-parallax="y: 200,0,0; opacity: 1,1,0">Descubre c&oacute;mo <span uk-icon="icon: arrow-right; ratio: 1.5"></span></a>
                </div>
            </li>
        </ul>
        <div className="uk-position-bottom uk-overlay uk-overlay-fd uk-height-xsmall uk-animation-slide-bottom uk-visible@m">
            <div className="uk-container">
                <BannerContactForm />
            </div>
        </div>
    </div>
);





const BannerContactForm = () => {

    const formik = useFormik({
        initialValues: {
            partner: '',
            rfc: '',
            razonsocial: '',
            contacto: '',
            email: '',
            telefono: '',
            campania: '',
            captcha: ''
        },
        onSubmit: values => {
            document.getElementById("enviarForm").setAttribute("disabled", "")
            let t = ''
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(SECRET_KEY_reCAPTCHA, { action: 'submit' }).then(function (token) {
                    t = token
                });
            })
            setTimeout(() => {
                values.captcha = t
                axios.post(FORM_ENDPOINT, null, {
                    params: {
                        partner: values.partner,
                        rfc: values.rfc,
                        razonsocial: values.razonsocial,
                        contacto: values.contacto,
                        email: values.email,
                        telefono: values.telefono,
                        campania: values.campania,
                        captcha: values.captcha
                    }
                })
                    .then(res => {
                        formik.resetForm();
                        const s = document.createElement('script');
                        const scriptText = document.createTextNode("UIkit.modal.dialog('<p class=\"uk-padding\">" + res.data + "</p>')");
                        s.type = 'text/javascript';
                        s.appendChild(scriptText)
                        document.body.appendChild(s);
                        document.getElementById("enviarForm").removeAttribute("disabled", "")
                    }).catch((error) => {
                        const s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.innerHTML = "UIkit.modal.dialog('<p class=\"uk-padding\">Ocurrió un error al registrar la informacion</p>')";
                        document.body.appendChild(s);
                    })
            }, 1000);
        },
    });


    return (
        <form id="banner-contact-form" onSubmit={formik.handleSubmit}>
            <div className="uk-grid-collapse uk-child-width-1-2@s" uk-grid={"true".toString()}>
                <div>
                    <div className="uk-padding-small">D&aacute;janos tus datos para ser contactado por un ejecutivo de ventas</div>
                </div>
                <div>
                    <div className="uk-padding-small uk-float-right">
                        <div className="uk-grid-small uk-child-width-auto uk-grid">
                            ¿Ya eres cliente?
                            <label>
                                <input className="uk-input uk-form-width-medium uk-form-small" type="text" placeholder="Captura tu RFC" maxLength="13" id="rfc" name="rfc" onChange={formik.handleChange} value={formik.values.rfc} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-grid-collapse uk-child-width-expand@s" uk-grid={"true".toString()}>
                <div>
                    <div className="uk-padding-small">
                        <label><input className="uk-input uk-form-small" type="text" placeholder="Nombre" maxLength="50" id="razonsocial" name="razonsocial" onChange={formik.handleChange} value={formik.values.razonsocial} required /></label>
                    </div>
                </div>
                <div>
                    <div className="uk-padding-small">
                        <label><input className="uk-input uk-form-small" type="email" placeholder="Email" maxLength="150" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} required /></label>
                    </div>
                </div>
                <div>
                    <div className="uk-padding-small">
                        <label><input className="uk-input uk-form-small" type="text" placeholder="Teléfono" maxLength="10" id="telefono" name="telefono" onChange={formik.handleChange} value={formik.values.telefono} required /></label>
                    </div>
                </div>
                <div className="uk-float-right">
                    <div className="uk-padding-small">
                        <button type="submit" className="uk-button uk-button-primary uk-button-small uk-width-expand uk-border-rounded topbar-buttons" id="enviarForm">Enviar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


const SolucionesSection = () => (
    <div className="uk-container-expand soluciones-background uk-height-large">
        <div className="uk-container">
            <div className="uk-grid-collapse uk-child-width-1-2@s  uk-margin-xlarge-top uk-margin-large-bottom" uk-grid={"true".toString()} uk-height-match=".soluciones-background">
                <div>
                    <div className="uk-card uk-card-body green-text uk-text-justify" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                        <h2 className="uk-heading-divider uk-text-bold green-text">SOLUCIONES EN FACTURACI&Oacute;N</h2>
                        <h3 className="green-text">Servicios especializados de facturaci&oacute;n, timbrado y herramientas digitales para todo tipo de contribuyentes.</h3>
                        <p>Nuestro principal objetivo es agilizar e integrar los procesos administrativos de nuestros clientes; para que ellos puedan dedicar su tiempo y recursos en actividades que generen valor a sus negocios.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);


const SolucionesEspecializadasSection = (props) => (
    <div className="uk-container-expand uk-background-gradient">
        <div className="uk-container uk-padding uk-text-center uk-marging-top">
            <h1 className="uk-heading-line uk-light" uk-scrollspy="cls: uk-animation-fade; repeat: true"><span>Soluciones Especializadas</span></h1>
            <p className="uk-margin-large-bottom uk-light" uk-scrollspy="cls: uk-animation-fade; repeat: true">Nuestra amplia gama de servicios nos hacen accesibles para todos los contribuyentes sin importar su actividad.</p>
            <div uk-slider={"true".toString()} className="uk-light">
                <div className="uk-position-relative">
                    <div className="uk-slider-container uk-padding-small">
                        <ul className="uk-slider-items uk-child-width-1-3@s uk-grid" uk-grid={"true".toString()} uk-height-match="target: > a > li > .uk-card; row: false">
                            <CreateCards apps={apps} />
                        </ul>
                    </div>
                    <div className="uk-hidden@s">
                        <a className="uk-position-center-left uk-position-small uk-light" href={props.url} uk-slidenav-previous={"true".toString()} uk-slider-item="previous"></a>
                        <a className="uk-position-center-right uk-position-small uk-light" href={props.url} uk-slidenav-next={"true".toString()} uk-slider-item="next"></a>
                    </div>
                    <div className="uk-visible@s">
                        <a className="uk-position-center-left-out uk-position-small uk-light" href={props.url} uk-slidenav-previous={"true".toString()} uk-slider-item="previous"></a>
                        <a className="uk-position-center-right-out uk-position-small uk-light" href={props.url} uk-slidenav-next={"true".toString()} uk-slider-item="next"></a>
                    </div>
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
            </div>
        </div>
    </div >
);


function CreateCards(props) {
    const content = props.apps.map((app, i) =>
        <a href={app.url} target={app.target} key={i}>
            <li uk-scrollspy="cls: uk-animation-fade uk-animation-fast; repeat: true; delay: 100;">
                <div className="uk-card uk-card-default uk-border-rounded">
                    <div className="uk-card-media-top">
                        <img src={app.logo} alt="" className='appLogos' />
                    </div>
                    <div className="uk-card-body">
                        <p className="uk-text-justify">{app.desc}</p>
                    </div>
                </div>
            </li>
        </a>
    );
    return content;
}

const ContratarSection = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding uk-maring-xlarge-top">
            <h1 className="uk-heading green-text" uk-scrollspy="cls: uk-animation-fade; repeat: true"><span>¿Por qu&eacute; contratar un servicio de facturaci&oacute;n electr&oacute;nica con Formas Digitales?</span></h1>
            <div className="uk-child-width-1-3@m green-text uk-flex-center" uk-grid={"true".toString()} uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 150; repeat: true" uk-height-match="target: > div > .uk-card">
                <CreateBenefits benefits={benefits} />
            </div>
        </div>
    </div>
);

function CreateBenefits(props) {
    const content = props.benefits.map((benefit, i) =>
        <div key={i}>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
                <h3 className="uk-card-title uk-text-small uk-text-bold"><img src={benefit.icon} alt="" width={benefit.icon_width} height={benefit.icon_height} className="uk-margin-right" />{benefit.title}</h3>
                <p className="uk-line-height-small">{benefit.desc}</p>
            </div>
        </div>
    );
    return content;
}

const OurPartners = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding uk-text-center uk-marging-top">
            <h1 className="uk-heading-line green-text" uk-scrollspy="cls: uk-animation-fade; repeat: true"><span>Nuestros Partners</span></h1>
            <div className="uk-grid-small uk-child-width-expand@s uk-text-center" uk-grid={"true".toString()}>
                <div>
                    <div className="uk-card uk-card-body" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                        <a href="/partners/aspel"><img src={aspelLogo} alt="" width="150" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div >
);


export default Home;