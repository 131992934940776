import benefit_1 from '../../images/icons/portal_benefits/formas-digitales-icono-navegacion-segura.png'
import benefit_2 from '../../images/icons/portal_benefits/formas-digitales-icono-generador-pdf.png'
import benefit_3 from '../../images/icons/portal_benefits/formas-digitales-icono-reportes-excel.png'
import benefit_4 from '../../images/icons/portal_benefits/formas-digitales-icono-catalogos-clientes.png'
import benefit_5 from '../../images/icons/portal_benefits/formas-digitales-icono-recibo-nomina.png'
import benefit_6 from '../../images/icons/portal_benefits/formas-digitales-icono-acceso-addendador.png'
import benefit_7 from '../../images/icons/portal_benefits/formas-digitales-icono-soporte-tecnico.png'

const benefits_left = [
    { id: 'lb_1', img: benefit_1, desc: 'Navegación segura y amigable.' },
    { id: 'lb_2', img: benefit_2, desc: 'Descarga tus CFDI’s de forma masiva en formatos PDF / XML' },
    { id: 'lb_3', img: benefit_3, desc: 'Emitir recibo de nómina.' },
    { id: 'lb_4', img: benefit_4, desc: 'Descarga reportes en formato CSV / TXT, con diferentes filtros.' },
    { id: 'lb_5', img: benefit_5, desc: 'Puedes generar usuarios para que los mismos puedan consultar sus documentos timbrados con FORMAS DIGITALES®, ya sea como emisores o receptores.' },
    { id: 'lb_6', img: benefit_6, desc: 'Acceso a tu Administrador de timbres.' },
    { id: 'lb_7', img: benefit_7, desc: 'Cuenta con soporte técnico de especialistas vía telefónica, email, chat o remoto disponible de Lunes a Viernes de 8:30 a.m. a 07:00 p.m..' }
]

const Central = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <p>Descarga de manera masiva todos tus CFDI´s timbrados por Formas Digitales® desde un solo sitio.</p>
            <h2 className="uk-heading-bullet">Beneficios</h2>
            <div className="uk-child-width-1-1@s uk-grid-collapse" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card; row: false">
                <div>
                    <table className="uk-table uk-table-responsive uk-table-striped uk-table-divider uk-table-small uk-table-middle">
                        <tbody>
                            <Benefits benefits={benefits_left} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
);


function Benefits(props) {
    const items = props.benefits.map((benefit) =>
        <tr key={benefit.id}>
            <td><img src={benefit.img} alt="" /></td>
            <td>{benefit.desc}</td>
        </tr>
    )
    return items
}

export default Central;