import React from 'react'
import PaquetesList from './PaquetesList'
import tarjetas from '../images/tarjetas.png'

const Precios = (list_portal) => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large">
            <div uk-filter="target: .js-filter; index: 0; animation: fade;">
                <h2 className="uk-heading-bullet">Tipo de servicio</h2>
                <ul className="uk-subnav uk-subnav-pill" id="paquetesSwitcher">
                    <li className="uk-active" uk-filter-control=".tag-portal"><a href="#">Portal de facturación y nómina en la nube</a></li>
                    <li uk-filter-control=".tag-timbrado"><a href="#">Timbrado Web Service</a></li>
                </ul>
                <h2 className="uk-heading-bullet">¿Qué adquiere en la compra de su paquete?</h2>
                <div className="js-filter">
                    <p className="tag-portal">La utilización total de los CFDI's adquiridos y/o un año de acceso a su cuenta, a través del portal de Formas Digitales para realizar sus operaciones, (lo que ocurra primero).</p>
                    <p className="tag-timbrado">La cantidad de timbres contratados no tienen fecha de caducidad, contaras con cancelaciones sin costo y un portal administrador.</p>
                </div>
                <table className="uk-table uk-table-justify uk-table-divider uk-table-hover uk-table-responsive">
                    <thead>
                        <tr className="table-header">
                            <th>PAQUETE</th>
                            <th>DESCRIPCIÓN</th>
                            <th>NO. OPERACIONES</th>
                            <th>PRECIO *IVA INCLUIDO</th>
                            <th>LO QUIERO</th>
                        </tr>
                    </thead>
                    <tbody className="js-filter">
                        <PaquetesList />
                    </tbody>
                </table>
            </div>
            <div className="uk-text-center">
                <img src={tarjetas} alt="tarjetas" />
            </div>
        </div>
    </div>
);

export default Precios;