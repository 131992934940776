const vacancies = [
    {
        jobtitle: 'Desarrollador Java Jr.',
        jobtype: 'Tiempo completo',
        description: 'Licenciatura en Ingeniería de Software/Informática o equivalente concluída.',
        qualifications: '<br><ul> <li>Desarrollo y mantenimiento de aplicaciones web usando Java.</li> <li>Conocimiento en desarrollo y consumo de servicios rest y soap.</li> <li>Documentar adecuadamente el código y los procesos para facilitar el mantenimiento y futuras mejoras.</li> <li>Conocimiento de MySQL u otras bases de datos relacionales.</li> <li>Conocimiento en desarrollo front-end.</li> <li>Mantener la calidad y garantizar la capacidad de respuesta de las aplicaciones.</li> <li>Comprensión e implementación de seguridad y protección de datos.</li> <li>Manejo de sistema de control de versiones (Git).</li> </ul>',
        experience: '2 años de experiencia.',
        benefits: '<ul> <li>Prestaciones de ley</li> <li>Seguro de gastos médicos mayores</li> </ul>',
        pay: '$18,000 - $20,000 al mes',
        worklocation: 'Vacante presencial',
        contactphone: '<span className="uk-margin-small-right" uk-icon="receiver"></span> 33 3777 1637',
        contactmail: '<span className="uk-margin-small-right" uk-icon="mail"></span> ti@formasdigitales.com.mx'
    }
]


const BolsaTrabajo = () => (
    <div className="uk-container-expand">
        <div className="uk-container uk-padding-large uk-text-justify">
            <h2 className="uk-heading-bullet">Vacantes</h2>
            {/* Vacancies */}
            <div className="uk-child-width-1-1@s" data-uk-grid="">
                <ul data-uk-accordion>
                    <Vacancies vacancies={vacancies} />
                </ul>
            </div>
        </div>
    </div>
);

function Vacancies(props) {
    let items = 'No hay vacantes actualmente.';
    let open = 'uk-box-shadow-small uk-padding-small';
    if (Object.keys(props.vacancies).length === 1) {
        open = 'uk-box-shadow-small uk-padding-small uk-open';
    }
    if (Object.keys(props.vacancies).length !== 0) {
        items = props.vacancies.map((vacancy, i) =>
            <>
                <li className={open} key={i}>
                    <a className="uk-accordion-title uk-text-normal" href="#">{vacancy.jobtitle}</a>
                    <div className="uk-accordion-content">
                        <div className="uk-padding">
                            <h5 className="uk-margin-bottom-small">Requisitos</h5>
                            <div dangerouslySetInnerHTML={{ __html: vacancy.description }}></div>
                            <div dangerouslySetInnerHTML={{ __html: vacancy.experience }}></div>
                            <div dangerouslySetInnerHTML={{ __html: vacancy.qualifications }}></div>
                            <h5>Prestaciones</h5>
                            <div dangerouslySetInnerHTML={{ __html: vacancy.benefits }}></div>
                            {/* <h5>Salario</h5> */}
                            {/* <div dangerouslySetInnerHTML={{ __html: vacancy.pay }}></div> */}
                            <h5>Observaciones</h5>
                            <div dangerouslySetInnerHTML={{ __html: vacancy.worklocation }}></div>
                            <h5>Contacto</h5>
                            <ul className="uk-list">
                            <li dangerouslySetInnerHTML={{ __html: vacancy.contactphone }}></li>
                            <li dangerouslySetInnerHTML={{ __html: vacancy.contactmail }}></li>
                            </ul>
                        </div>
                    </div>
                </li>
            </>
        )
    }
    return items
}


export default BolsaTrabajo;