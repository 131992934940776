const NotFound = () => (
    <div className="uk-container-expand" uk-height-viewport="expand: true">
        <div className="uk-container uk-padding-large uk-text-justify">
            <div className="uk-text-center uk-animation-slide-top-small">
                <span className="error_code bg-gradient-text uk-animation-scale-down">
                    404
                </span>
                <div className="uk-text-bold uk-text-large">
                    ¡Oops! el recurso que buscas no existe
                </div>
                <a href="/" className="uk-text-large">Volver al inicio</a>
            </div>
        </div>
    </div>
);


export default NotFound;