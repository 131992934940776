import React from 'react';
import axios from 'axios';

const PROD_PRICE_SERVICE_URL = 'https://forsedi.facturacfdi.mx/admindigital/paquetesListaPublica'
const DEV_PRICE_SERVICE_URL = 'https://dev.facturacfdi.mx:8083/admindigital/paquetesListaPublica'

export default class PaquetesList extends React.Component {
    state = {
        paquetes: []
    }

    componentDidMount() {
        axios.get(PROD_PRICE_SERVICE_URL)
            .then(res => {
                const paquetes = res.data
                this.setState({ paquetes })
            })
    }

    render() {
        return (
            <>
                {this.state.paquetes.map(paquete =>
                    <tr className={paquete.itipotransaccion === 1 ? "tag-timbrado" : "tag-portal"} key={paquete.id}>
                        <td>{paquete.vchnombre}</td>
                        <td>{paquete.vchdescripcion}</td>
                        <td>{numericFormat(paquete.icantidad)}</td>
                        <td>{currencyFormat(paquete.mprecio)}</td>
                        <td><a href={"https://forsedi.facturacfdi.mx/admindigital/registro?idpaquete=" + paquete.id} target="_blank" rel="noreferrer" className="uk-link-fd">¡Contratar!</a></td>
                    </tr>
                )}
            </>
        )
    }
}

function numericFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}