import crolExpert from '../../images/partners/crol/Logo-Crol-XPERT-01-Transparencia-300x153.png'
import flujoCrol from '../../images/partners/crol/iconos-moodulos-Crol-1024x930-1-512x465.png'

const Crol = () => (
    <div className="uk-container-expand" uk-height-viewport="expand: true">
        <div className="uk-container uk-padding-large uk-text-justify">
            <div className="uk-margin">
                <img src={crolExpert} alt="crolexpert_logo" width="192" />
            </div>
            <h2 className="uk-heading-bullet">¿Qué es CROL?</h2>
            <div className="uk-margin">
                <p>Es un software administrativo en la nube, para las micro, pequeñas y medianas empresas en México, que les permite controlar y administrar sus ventas, compras, inventarios, bancos y la contabilidad de su negocio en un solo lugar 24 / 7.</p>
            </div>
            <div className="uk-card uk-card-default uk-card-body uk-padding-small uk-border-rounded uk-box-shadow-small uk-width-1-2@s uk-margin-medium-bottom">
                <iframe title="crol_video" aria-hidden="true" src="https://www.youtube.com/embed/6reD5yW-osM" width="100%" height="360" frameBorder="0" allowFullScreen uk-responsive={"true".toString()} uk-video="automute: true; autoplay: true"></iframe>
            </div>
            <div>
                <p>Pruébalo SIN COSTO por 15 días con 25 timbres incluidos.</p>
                <p>Contrata por $300.00 al mes más I.V.A</p>
            </div>
            <h2 className="uk-heading-bullet">Beneficios</h2>
            <div>
                <img src={flujoCrol} alt="flujo_crol" />
            </div>
        </div>
    </div>
);

export default Crol;