import logoFD_fullwhite from '../images/logoFD_full_white.svg'

const Footer = () => (
    <div className="uk-container-expand uk-footer-fd bottom-footer">
        <div className="uk-container">
            <nav className="uk-navbar-container uk-navbar-transparent uk-light uk-visible@s" uk-navbar={"true".toString()}>
                <div className="uk-navbar-left">
                    <ul className="uk-navbar-nav">
                        <a className="uk-navbar-item uk-logo" href="/"><img src={logoFD_fullwhite} alt="logoFD" width="128" /></a>
                        <a href="/aviso-de-privacidad" className="uk-link-reset uk-navbar-item">Aviso de privacidad</a>
                        <a href="/politicas-de-privacidad" className="uk-link-reset uk-navbar-item">Políticas de privacidad</a>
                        <a href="https://www.portal.facturacfdi.mx/" target="_blank" rel="noreferrer" className="uk-link-reset uk-navbar-item">Servicio Gratuito</a>
                        <a href="/bolsa-de-trabajo" className="uk-link-reset uk-navbar-item">Bolsa de trabajo</a>
                    </ul>
                </div>
                <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                        <li className="uk-navbar-item">Derechos Reservados &copy; Formas Digitales 2021</li>
                    </ul>
                </div>
            </nav>
            <div className="uk-container-expand uk-hidden@s">
                <div className="uk-child-width-1-1 uk-text-center uk-light" uk-grid={"true".toString()}>
                    <div>
                        <div className="uk-card uk-card-body"><a href="/"><img src={logoFD_fullwhite} width="128" alt="logoFD_w" /> </a></div>
                    </div>
                </div>
                <div className="uk-grid-small uk-child-width-1-1 uk-flex-center uk-text-center uk-light" uk-grid={"true".toString()}>
                    <div>
                        <div><a href="/aviso-de-privacidad" className="uk-link-reset">Aviso de privacidad</a></div>
                    </div>
                    <div>
                        <div><a href="/politicas-de-privacidad" className="uk-link-reset">Políticas de privacidad</a></div>
                    </div>
                    <div>
                        <div><a href="https://www.portal.facturacfdi.mx/" target="_blank" rel="noreferrer" className="uk-link-reset">Servicio Gratuito</a></div>
                    </div>
                    <div>
                        <div><a href="/bolsa-de-trabajo" className="uk-link-reset">Bolsa de Trabajo</a></div>
                    </div>
                </div>
                <div className="uk-navbar-item uk-light">Derechos Reservados &copy; Formas Digitales 2021</div>
            </div>
        </div>
    </div>
);

export default Footer;