import icon_dev from '../../images/icons/distribuidores/formas-digitales-icono-desarrolladores-min.png'
import icon_unlimited from '../../images/icons/distribuidores/formas-digitales-icono-timbre-sin-vigencia-min.png'
import icon_support from '../../images/icons/distribuidores/formas-digitales-icono-soporte-tecnico-min.png'
import icon_license from '../../images/icons/distribuidores/formas-digitales-icono-licencia-distribuidor-autorizado-min.png'
import icon_timb from '../../images/icons/distribuidores/formas-digitales-icono-timbrado-min.png'
import icon_cash from '../../images/icons/distribuidores/formas-digitales-icono-inversion-min.png'
import icon_hands from '../../images/icons/distribuidores/formas-digitales-icono-relacion-clientes-min.png'
import icon_grow from '../../images/icons/distribuidores/formas-digitales-icono-crecer-negocio-min.png'

const Distribuidores = () => (
    <div className="uk-container-expand">
        <Desarrolladores />
        <Recomienda />
        <Oportunidad />
        <AlmacenDigital />
    </div>
);


const Desarrolladores = () => (
    <div className="uk-container uk-padding-large uk-text-justify">
        <h2 className="uk-heading-bullet">Desarrolladores de Software</h2>
        <table className="uk-table uk-table-responsive">
            <tbody>
                <tr>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_dev} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Ideal para los desarrolladores de software especializados en la facturación electrónica que buscan ofrecer un servicio de timbrado automático.</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_unlimited} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Timbres sin vigencia para distribuidor.</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_support} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Soporte técnico y asistencia ilimitada y sin costo.</td>
                </tr>
                <tr>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_license} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Licencia de Distribuidor Autorizado ¡Sin costo!</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_license} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Administrador con los siguientes beneficios: Alta de cuentas, bloqueo de cuentas, acreditación de timbres, alertas y más.</td>
                </tr>
            </tbody>
        </table>
    </div>
);

const Recomienda = () => (
    <div className="uk-container-expand bg-recomienda uk-padding-large uk-text-center uk-text-middle uk-height-1-1">
        <h1 className="uk-text-white uk-text-uppercase">¡recomienda y gana con formas digitales!</h1>
        <h1 className="uk-link-fd uk-text-uppercase">Obtén un <span className="uk-link-fd uk-heading-medium uk-text-bold">10%</span> adicional</h1>
        <h1 className="uk-text-white uk-text-uppercase uk-text-large">Adicional sobre tus transacciones adquiridas por cada solución comprada por alguno de tus recomendados</h1>
    </div>
);

const Oportunidad = () => (
    <div className="uk-container uk-padding-large uk-text-justify">
        <h2 className="uk-heading-bullet">Oportunidad de Negocio</h2>
        <table className="uk-table uk-table-responsive">
            <tbody>
                <tr>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_timb} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Si cuentas con una cartera de clientes ofrece un Servicio de Timbrado.</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_cash} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Gana hasta el 100% de tu inversión.</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_hands} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Fortaleces la relación con tus clientes.</td>
                </tr>
                <tr>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_grow} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Crecerás tu negocio.</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"><img className="green-background uk-border-rounded" style={{ padding: '5px' }} src={icon_support} alt="" /></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%">Soporte técnico y asistencia ilimitada y sin costo.</td>
                    <td className="uk-text-middle uk-padding-remove" width="10%"></td>
                    <td className="uk-text-middle uk-padding-remove uk-text-justify" width="25%"></td>
                </tr>
            </tbody>
        </table>
    </div>
);


const AlmacenDigital = () => (
    <div className="uk-container uk-margin-large-bottom">
        <div className="uk-child-width-expand@s" uk-grid={"true".toString()} uk-height-match="target: > div > .uk-card">
            <div>
                <div className="uk-card uk-card-body">
                    <h3 className="green-text">Almacén Digital</h3>
                    <p>En el sitio de desarrolladores podrás hacer tus pruebas de integración al web service en donde encontraras los diferentes tipos de lenguaje, el nuevo método de cancelación, ejemplos de XML, validación de XML, sellado de XML, timbrado masivo etc.</p>
                </div>
            </div>
            <div>
                <div className="uk-card uk-card-default uk-card-body uk-padding-small uk-border-rounded uk-box-shadow-bottom uk-box-shadow-small">
                    <iframe title="distribuidores" aria-hidden="true" src="https://www.youtube.com/embed/3ufl0-OqkzM" width="540" height="360" frameBorder="0" allowFullScreen uk-responsive={"true".toString()} uk-video="automute: true; autoplay: true"></iframe>
                </div>
            </div>
        </div>
    </div>
);

export default Distribuidores;