import './App.css'
import './media-queries.css'
import TopBar from './components/TopBar'
import MainMenu from './components/MainMenu'
import OffCanvasMenu from './components/OffCanvasMenu'
import Root from './components/Root'
import SocialMedia from './components/SocialMedia'
import ToTop from './components/ToTop'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'



function App() {
  return (
    <div className="App">
      <TopBar />
      <MainMenu />
      <OffCanvasMenu />
      <SocialMedia />
      <ToTop />
      <Root />
      <ContactForm/>
      <Footer />
    </div>
  );
}

export default App;